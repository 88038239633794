export class UserPermissions {
  constructor(private permissions: string[]) {

  }

  public mayAlterLanguage(languageCode: string): boolean {
    return this.permissions.includes('LANGUAGE_CODE_' + languageCode);
  }


  public hasRole(role: string): boolean {
    return this.permissions.includes('ROLE_' + role.toUpperCase());
  }

  public getRoles(): string[] {
    return this.permissions
      .filter(permission => permission.startsWith('ROLE_'))
      .map(permission => permission.replace('ROLE_', ''));
  }

  public getLanguageCodes(): string[] {
    return this.permissions.filter(str => str.startsWith('LANGUAGE_CODE_'))
      .map(str => str.replace('LANGUAGE_CODE_', ''));
  }

}
