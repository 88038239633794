import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {Language} from '../../../../services/hal/language/language';
import {combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {ModificationLayerService} from '../../../../services/hal/modificationlayer/modification-layer.service';
import {shareReplay, switchMap} from 'rxjs/operators';
import {ModificationCreationRequest} from './modification-creation-request';

@Component({
  selector: 'app-add-modification-to-layer',
  templateUrl: './add-modification-to-layer.component.html',
  styleUrls: ['./add-modification-to-layer.component.css']
})
export class AddModificationToLayerComponent implements OnInit, OnDestroy {

  @Input() translationKey$: Observable<TranslationKey>;
  @Input() language$: Observable<Language>;
  @Output() modificationLayerCreationRequest: EventEmitter<ModificationCreationRequest> = new EventEmitter<ModificationCreationRequest>();

  unusedModificationLayers$: Observable<ModificationLayer[]>;

  doFetch: Subject<void> = new ReplaySubject(1);

  currentLanguage: Language;
  currentKey: TranslationKey;

  currentKeySubscription: Subscription;
  currentLanguageSubscription: Subscription;

  constructor(private modificationLayerService: ModificationLayerService) {
  }

  ngOnInit() {
    this.unusedModificationLayers$ = this.doFetch.pipe(
      switchMap(() =>
        combineLatest(this.translationKey$, this.language$).pipe(
          switchMap(translationKeyAndLanguage => this.modificationLayerService.findAllUnusedForTranslationKeyAndLanguage(translationKeyAndLanguage[0], translationKeyAndLanguage[1]))
        )
      ),
      shareReplay(1)
    );

    this.currentKeySubscription = this.translationKey$.subscribe(newKey => this.currentKey = newKey);
    this.currentLanguageSubscription = this.language$.subscribe(newLanguage => this.currentLanguage = newLanguage);
  }

  fetchLayers() {
    this.doFetch.next();
  }

  createForLayer($event: ModificationLayer) {
    this.modificationLayerCreationRequest.emit(
      new ModificationCreationRequest(this.currentLanguage, this.currentKey, $event)
    );
  }

  ngOnDestroy(): void {
    this.currentKeySubscription.unsubscribe();
    this.currentLanguageSubscription.unsubscribe();
  }

  createIdFromKeyName(keyName: string): string{
    return 'layerDropdown_' + keyName.replace('"', '');
  }
}
