<div class="container-fluid">
  <div class="row">
    <div class="col-1"><b>Edit?</b></div>
    <div class="col-11"><b>Action</b></div>
  </div>
  <ng-container *ngFor="let trigger of (triggerableUrls$ | async); index as i">
    <div [ngClass]="{'row': true, 'second': i % 2 == 1}" style="padding-top: 5px; padding-bottom: 5px;">
      <div class="col">
        <app-triggerable-url [editable]="true" [triggerableUrl]="trigger"></app-triggerable-url>
      </div>
    </div>
  </ng-container>
</div>
