import {Injectable} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {Release} from './release';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService extends ImprovedRestService<Release> {

  constructor() {
    super(Release);
  }

  public getLatestRelease(): Observable<Release> {
    return this.searchSingle('latestRelease');
  }
}
