import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslationService} from '../../../services/hal/translation/translation.service';
import {ReleaseService} from '../../../services/hal/release/release.service';
import {Language} from '../../../services/hal/language/language';
import {Router} from '@angular/router';

@Component({
  selector: 'app-quick-create-translation-key',
  templateUrl: './quick-create-translation-key.component.html',
  styleUrls: ['./quick-create-translation-key.component.css']
})
export class QuickCreateTranslationKeyComponent implements OnInit {

  @ViewChild('cancelButton', { static: true })
  private cancelButton: ElementRef<HTMLButtonElement>;

  @ViewChild('translation', { static: true })
  private translation: ElementRef<HTMLInputElement>;

  @ViewChild('keyName', { static: true })
  private keyName: ElementRef<HTMLInputElement>;

  wait: boolean;
  error: Error;
  success: boolean;
  private warnOfImproperKey: boolean;


  keyNameInput: string = '';
  translationInput: string = '';

  constructor(private translationService: TranslationService, private releaseService: ReleaseService, private router: Router) {
  }

  ngOnInit() {
  }

  public async doCreateTranslationKey(jumpToTranslation: boolean) {
    this.wait = true;
    this.error = null;
    this.success = false;
    this.createTranslationKey(this.keyNameInput, this.translationInput)
      .then(() => this.closeModalAndNavigateToKeyIfRequested(this.keyNameInput, jumpToTranslation))
      .catch(
        reject => this.error = reject
      ).finally(
      () => this.wait = false
    );
  }

  public async createTranslationKey(name: string, translation: string) {
    const release = await this.releaseService.getLatestRelease().toPromise();
    const payload: Map<string, string> = new Map<string, string>();
    payload[name] = translation;

    const baseLanguage = new Language();
    baseLanguage.languageCode = 'en';

    return this.translationService.updateTranslations(release, baseLanguage, payload).toPromise();
  }

  private closeModalAndNavigateToKeyIfRequested(name: string, jumpToTranslation: boolean) {
    this.resetModal();
    this.success = true;
    if (!jumpToTranslation) {
      return;
    }
    this.closeModal();
    this.router.navigate(['translate'], {
      queryParams: {
        language: 'sv',
        translationKey: name
      }
    });
  }

  private resetModal() {
    this.keyNameInput = '';
    this.translationInput = '';
  }

  closeModal() {
    this.cancelButton.nativeElement.click();
  }

  onPopupRequested() {
    setTimeout(() => this.keyName.nativeElement.focus(), 500);
  }
}
