<div class="container-fluid">
  <div class="row">
    <div class="col-11">
      <app-language-list (languageSelectedEvent)="onLanguageSelected($event)"
                         [$selectedLanguageCode]="$selectedLanguageCode"></app-language-list>

    </div>

  </div>

  <div class="row" *ngIf="language$ | async" style="margin-top: 10px">
    <div class="col-11">
      <app-modification-layer-list [text]="'Select ModificationLayer (Optional):'"
                                   (layerSelectedEvent)="onLayerSelected($event)"
                                   [$selectedLayerTag]="$selectedLayerTag"></app-modification-layer-list>
    </div>
    <div class="col-1" *ngIf="layer$ | async">
      <button type="button" class="btn btn-sm btn-danger" (click)="clearLayer()">Clear</button>
    </div>

  </div>
</div>
<hr>


<app-the-sink [language$]="language$" [modificationLayer$]="layer$"></app-the-sink>
