import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {Language} from '../../../../services/hal/language/language';
import {forkJoin, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, flatMap, map, shareReplay} from 'rxjs/operators';
import {Translation} from '../../../../services/hal/translation/translation';
import {UserService} from '../../../../services/domain/user/user.service';
import {TranslationKeyService} from '../../../../services/hal/translationkey/translation-key.service';
import {ReleaseService} from '../../../../services/hal/release/release.service';
import {TranslationsForKeyAndLanguageComponent} from '../translations-for-key-and-language/translations-for-key-and-language.component';
import {ModificationCreationRequest} from '../add-modification-to-layer/modification-creation-request';
import {TranslationContextLanguages} from '../../../page/translate/translation-context-languages';
import {TranslationEventDto} from './translation-event-dto';


@Component({
  selector: 'app-translation-key',
  templateUrl: './translation-key.component.html',
  styleUrls: ['./translation-key.component.css']
})
export class TranslationKeyComponent implements OnInit, OnDestroy {

  @Input() translationKey$: Observable<TranslationKey>;
  @Input() translationKey: TranslationKey;
  @Input() translationContextLanguages$: Observable<TranslationContextLanguages>;
  @Output() currentLanguageWasTranslatedEvent?: EventEmitter<TranslationEventDto> = new EventEmitter();

  baseLanguage$: Observable<Language>;
  currentLanguage$: Observable<Language>;
  additionalLanguage$: Observable<Language>;

  triggerBaseLanguageTranslationUpdate: Subject<Translation> = new ReplaySubject();
  triggerCurrentLanguageTranslationUpdate: Subject<Translation> = new ReplaySubject();
  triggerAdditionalLanguageTranslationUpdate: Subject<Translation> = new ReplaySubject();

  @ViewChild('currentLanguageTranslation', {static: true}) currentLanguageTranslation: TranslationsForKeyAndLanguageComponent;
  @ViewChild('additionalLanguageTranslation') additionalLanguageTranslation: TranslationsForKeyAndLanguageComponent;

  userSubscription: Subscription;

  userMayDeleteKeys = false;
  userMayCreateNewModifications = false;

  constructor(private userService: UserService, private translationKeyService: TranslationKeyService, private releaseService: ReleaseService) {
  }

  ngOnInit() {
    if (this.translationKey$ == null) {
      if (this.translationKey == null) {
        throw new Error('TranslationKey was null.');
      }
      this.translationKey$ = of(this.translationKey);
    }
    this.translationKey$ = this.translationKey$.pipe(shareReplay(1));
    this.baseLanguage$ = this.translationContextLanguages$.pipe(map(context => context.baseLanguage), shareReplay(1));
    this.currentLanguage$ = this.translationContextLanguages$.pipe(map(context => context.currentlyTranslatingLanguage), shareReplay(1));
    this.additionalLanguage$ = this.translationContextLanguages$.pipe(
      filter(context => context.additionalLanguage != null),
      map(context => context.additionalLanguage),
      shareReplay(1));

    this.userSubscription = this.userService.getUser().subscribe(user => {
      this.userMayDeleteKeys = user.authorities.hasRole('ADMIN');
      this.userMayCreateNewModifications = user.authorities.hasRole('ADMIN');
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  currentLanguageTranslationWasUpdatedByUser(translationEvent: TranslationEventDto) {
    this.triggerBaseLanguageTranslationUpdate.next(null);
    this.currentLanguageWasTranslatedEvent.emit(translationEvent);
  }

  baseLanguageTranslationWasUpdatedByUser(translationEvent: TranslationEventDto) {
    this.triggerCurrentLanguageTranslationUpdate.next(null);
    this.triggerAdditionalLanguageTranslationUpdate.next(null);
  }

  additionalLanguageTranslationWasUpdatedByUser(translationEvent: TranslationEventDto) {

  }

  isCurrentLanguageTranslated(): boolean {
    return this.currentLanguageTranslation.isTranslated();
  }

  enterEditModeForCurrentLanguageTranslation() {
    this.currentLanguageTranslation.setEditMode(true);
  }

  desistTranslationKey($event: MouseEvent) {
    forkJoin(this.translationKey$, this.releaseService.getLatestRelease()).pipe(
      flatMap(([key, release]) => this.translationKeyService.desistByNameAndRelease(key, release))
    ).toPromise()
      .finally(() => {
        window.location.reload();
      })
      .catch((error) => alert('There was a significant explosion while trying to desist you translation key: ' + error.message));
  }

  newModificationLayerRequest($event: ModificationCreationRequest) {
    this.currentLanguageTranslation.newModificationCreationRequest($event).catch(
      (error) => console.error('Error creating new modification layer:', error)
    );
  }
}
