import {ImprovedResource} from '../../improved-resource';
import {HateoasResource} from '@lagoshny/ngx-hateoas-client';
@HateoasResource('triggerableUrls')
export class TriggerableUrl extends ImprovedResource {

  name: string;
  url: string;
  method: HttpMethod;

}

export type HttpMethod = 'GET' | 'POST' | 'HEAD' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'TRACE';
