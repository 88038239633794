import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Language} from '../../../services/hal/language/language';
import {combineLatest, Observable, of, race, ReplaySubject, Subject, Subscription} from 'rxjs';
import {defaultIfEmpty, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {LanguageService} from '../../../services/hal/language/language.service';
import {TranslationContext} from './translation-context';
import {BaseLanguageService} from '../../../services/domain/base-language.service';
import {ReleaseService} from '../../../services/hal/release/release.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {TranslationStatusFilter} from './translation-status-filter';
import {InputDebounceComponent} from '../../controls/input-debounce/input-debounce.component';
import {SortOrder, SortProperty} from './sort-property';
import {ModificationLayerService} from '../../../services/hal/modificationlayer/modification-layer.service';
import {ModificationLayer} from '../../../services/hal/modificationlayer/modification-layer';
import {TranslationContextLanguages} from './translation-context-languages';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(1000, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(1000, style({opacity: 0}))
      ])
    ])
  ]
})
export class TranslateComponent implements OnInit, OnDestroy {
  @ViewChild('jumpWhenSavingCheckBox') jumpWhenSavingCheckBox: ElementRef<HTMLInputElement>;
  @ViewChild('jumpWhenSavingOnlyWhenUntranslatedCheckBox') jumpWhenSavingOnlyWhenUntranslatedCheckBox: ElementRef<HTMLInputElement>;
  @ViewChild('search') search: ElementRef<HTMLInputElement>;

  jumpToNextUntranslatedAfterSaving$: Subject<boolean> = new ReplaySubject(1);
  jumpToNextUntranslatedAfterSavingOnlyIfUntranslated$: Subject<boolean> = new ReplaySubject(1);
  translationContext$: Observable<TranslationContext> = new Observable();
  hideThatEnormousFilterBar: boolean = false;
  doFlashJumpSettings: boolean = false;

  @ViewChild('translationTextFilterComponent')
  translationTextFilterComponent: InputDebounceComponent;

  @ViewChild('translationKeyNameFilterComponent')
  translationKeyNameFilterComponent: InputDebounceComponent;

  @ViewChild('translatorNameFilterComponent')
  translatorNameFilterComponent: InputDebounceComponent;

  @ViewChild('KeyCreatorNameFilterComponent')
  KeyCreatorNameFilterComponent: InputDebounceComponent;

  @ViewChild('clearButton')
  clearButton: ElementRef;


  selectedAdditionalLanguageCode?: string = null;
  selectedLanguageCode?: string = null;

  $selectedLanguageCode: Subject<string> = new ReplaySubject(1);
  $selectedAdditionalLanguageCode: Subject<string> = new ReplaySubject(1);

  $initializedWithQueryParameters: Observable<ParamMap>;

  $triggerCreationOfNewContext: Subject<void> = new Subject();

  translationStatusFilter: TranslationStatusFilter = 'ALL';

  availableModificationLayers$: Observable<ModificationLayer[]>;

  languageQueryParameterSubscription: Subscription;
  translationKeyParameterSubscription: Subscription;
  additionalLanguageQueryParameterSubscription: Subscription;
  selectedLanguageCodeSubscription: Subscription;
  selectedAdditionalLanguageCodeSubscription: Subscription;
  anyFilterHasValueSubscription: Subscription;
  clearButtonShouldBeDisabledSubscription: Subscription;

  keyNameFilterHasValue$: Subject<boolean> = new ReplaySubject(1);
  translationContainsFilterHasValue$: Subject<boolean> = new ReplaySubject(1);
  latestTranslatorFilterHasValue$: Subject<boolean> = new ReplaySubject(1);
  keyCreatorFilterHasValue$: Subject<boolean> = new ReplaySubject(1);

  anyFilterHasValue: Subject<boolean> = new ReplaySubject<boolean>(1);

  currentTranslationTextFilter: string;
  currentTranslationKeyNameFilter: string;

  currentTranslatorTextFilter: string;
  currentKeyCreatorNameFilter: string;

  currentModificationLayerTagFilter: string = null;

  sortOrder: SortOrder = 'Descending';
  sortProperty: SortProperty = 'Key Created Date';

  clearButtonShouldBeDisabled: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private baseLanguageService: BaseLanguageService,
    private releaseService: ReleaseService,
    private modificationLayerService: ModificationLayerService
  ) {
  }

  ngOnInit() {
    this.jumpToNextUntranslatedAfterSaving$.next(true);
    this.jumpToNextUntranslatedAfterSavingOnlyIfUntranslated$.next(true);

    this.$initializedWithQueryParameters = this.activatedRoute.queryParamMap.pipe(shareReplay(1));


    this.languageQueryParameterSubscription = this.getQueryParameter('language')
      .subscribe(
        langCode => (langCode == null || langCode === '') ? this.$selectedLanguageCode.next('') : this.$selectedLanguageCode.next(langCode)
      );

    this.translationKeyParameterSubscription = this.getQueryParameter('translationKey')
      .subscribe(
        translationKey => (translationKey == null || translationKey === '') ? this.currentTranslationKeyNameFilter = null : this.newTranslationKeySearchFilter(translationKey)
      );

    this.selectedLanguageCodeSubscription = this.$selectedLanguageCode.subscribe(
      next => this.selectedLanguageCode = next
    );

    this.additionalLanguageQueryParameterSubscription = this.getQueryParameter('additionalLanguage')
      .subscribe(
        code => (code === null || code === '') ? this.$selectedAdditionalLanguageCode.next('') : this.$selectedAdditionalLanguageCode.next(code)
      );

    this.additionalLanguageQueryParameterSubscription = this.getQueryParameter('modificationLayer')
      .subscribe(
        code => (code === null || code === '') ? this.currentModificationLayerTagFilter = null : this.selectModificationLayerSearchFilter(code)
      );

    this.selectedAdditionalLanguageCodeSubscription = this.$selectedAdditionalLanguageCode.subscribe(
      next => this.selectedAdditionalLanguageCode = next
    );

    this.translationContext$ = race(this.$triggerCreationOfNewContext).pipe(
      switchMap(() =>
        combineLatest(
          this.mapQueryParameterToLanguage('language', true),
          this.mapQueryParameterToLanguage('additionalLanguage', false)
        )
      ),
      switchMap((currentAndAdditionalLanguage) => this.createTranslationContext(currentAndAdditionalLanguage[0], currentAndAdditionalLanguage[1])),
      shareReplay(1)
    );
    setTimeout(() => this.$triggerCreationOfNewContext.next(), 0);

    this.anyFilterHasValueSubscription = combineLatest(this.keyNameFilterHasValue$, this.translationContainsFilterHasValue$, this.latestTranslatorFilterHasValue$, this.keyCreatorFilterHasValue$).pipe(
      map((booleans: boolean[]) => booleans.includes(true))
    ).subscribe(anyHasValue => this.anyFilterHasValue.next(anyHasValue));

    this.clearButtonShouldBeDisabledSubscription = this.anyFilterHasValue.subscribe(value => this.clearButtonShouldBeDisabled = !value);

    this.availableModificationLayers$ = this.modificationLayerService.getAll().pipe(shareReplay(1));
  }

  private getQueryParameter(name: string) {
    return this.$initializedWithQueryParameters
      .pipe(
        filter(parameterMap => parameterMap.has(name)),
        map(parameterMap => parameterMap.get(name)),
        shareReplay(1)
      );
  }

  flashTheJumpSettings(): void {
    this.doFlashJumpSettings = true;
    setTimeout(() => {
      this.doFlashJumpSettings = false;
    }, 2000); // Adjust the duration as needed
  }

  mapQueryParameterToLanguage(parameterName: string, required: boolean): Observable<Language> {
    return this.$initializedWithQueryParameters
      .pipe(
        filter(queryMap => required ? queryMap.has(parameterName) : true),
        map(queryMap => queryMap.get(parameterName)),
        defaultIfEmpty(null),
        switchMap(languageCode => (languageCode == null || languageCode === '' ? of(null, null) : this.languageService.getByLanguageCode(languageCode))),
        shareReplay(1)
      );
  }

  onJumpingToNextTranslationAfterSave() {
    this.flashTheJumpSettings();
  }

  private async createTranslationContext(currentLanguage, additionalLanguage) {
    const language = await this.baseLanguageService.getBaseLanguage();
    const release = await this.releaseService.getLatestRelease().toPromise();
    return new TranslationContext(
      new TranslationContextLanguages(currentLanguage, language, additionalLanguage),
      release,
      this.translationStatusFilter,
      this.sortProperty,
      this.sortOrder,
      this.currentKeyCreatorNameFilter,
      this.currentTranslatorTextFilter,
      this.currentTranslationTextFilter,
      this.currentTranslationKeyNameFilter,
      this.currentModificationLayerTagFilter
    );
  }

  selectLanguage(language: Language) {
    this.selectedLanguageCode = language.languageCode;
    this.navigateToSelectedLanguageAndAdditionalLanguage();
  }

  navigateToSelectedLanguageAndAdditionalLanguage() {
    this.router.navigate(['translate'], {
      queryParams: {
        language: this.selectedLanguageCode,
        additionalLanguage: (this.selectedAdditionalLanguageCode == null ? '' : this.selectedAdditionalLanguageCode),
        translationKey: (this.currentTranslationKeyNameFilter == null ? null : this.currentTranslationKeyNameFilter)
      }
    });
  }

  onCheckBoxValueChangedForJumpWhenSaving(abc: UIEvent) {
    this.jumpToNextUntranslatedAfterSaving$.next(this.jumpWhenSavingCheckBox.nativeElement.checked);
    this.jumpWhenSavingOnlyWhenUntranslatedCheckBox.nativeElement.disabled = !this.jumpWhenSavingCheckBox.nativeElement.checked;
  }

  onCheckBoxValueChangedForJumpWhenSavingOnlyWhenUntranslated(event: MouseEvent) {
    this.jumpToNextUntranslatedAfterSavingOnlyIfUntranslated$.next(this.jumpWhenSavingOnlyWhenUntranslatedCheckBox.nativeElement.checked);

  }

  additionalLanguageSelected(language: Language) {
    this.selectedAdditionalLanguageCode = language.languageCode;
    this.navigateToSelectedLanguageAndAdditionalLanguage();
  }

  clearAdditionalLanguage() {
    this.selectedAdditionalLanguageCode = null;
    this.navigateToSelectedLanguageAndAdditionalLanguage();
  }

  onTranslationStatusFilterChange(clickedEntity: MouseEvent) {
    this.translationStatusFilter = this.getClickedValue(clickedEntity);
    this.updateTranslationContext();
  }

  onModificationLayerFilterChange(clickedEntity: MouseEvent) {
    this.selectModificationLayerSearchFilter(this.getClickedValue(clickedEntity));
  }

  private getClickedValue(clickedEntity: MouseEvent) {
    // @ts-ignore
    return clickedEntity.target.control.value;
  }

  private updateTranslationContext() {
    this.$triggerCreationOfNewContext.next();
  }


  ngOnDestroy(): void {
    this.additionalLanguageQueryParameterSubscription.unsubscribe();
    this.languageQueryParameterSubscription.unsubscribe();
    this.selectedLanguageCodeSubscription.unsubscribe();
    this.selectedAdditionalLanguageCodeSubscription.unsubscribe();
    this.translationKeyParameterSubscription.unsubscribe();
    this.anyFilterHasValueSubscription.unsubscribe();
    this.clearButtonShouldBeDisabledSubscription.unsubscribe();
  }

  newTranslationSearchFilter(text: string) {
    if (text === '') {
      this.currentTranslationTextFilter = null;
    } else {
      this.currentTranslationTextFilter = text;
    }
    this.updateTranslationContext();
  }

  newTranslationKeySearchFilter(text: string) {
    if (this.translationKeyNameFilterComponent !== undefined && this.translationKeyNameFilterComponent !== null) {
      this.translationKeyNameFilterComponent.set(text);
    }
    if (text === '') {
      this.currentTranslationKeyNameFilter = null;
    } else {
      this.currentTranslationKeyNameFilter = text;
    }
    this.updateTranslationContext();
  }

  selectModificationLayerSearchFilter(tag: string) {
    this.currentModificationLayerTagFilter = tag;
    this.updateTranslationContext();
  }

  clearSearchFilters() {
    this.translationKeyNameFilterComponent.clear();
    this.translationTextFilterComponent.clear();
    this.translatorNameFilterComponent.clear();
    this.KeyCreatorNameFilterComponent.clear();
    this.currentTranslationKeyNameFilter = null;
    this.currentTranslationTextFilter = null;
    this.currentTranslatorTextFilter = null;
    this.currentKeyCreatorNameFilter = null;
    this.updateTranslationContext();
  }

  onSortingChange($event: MouseEvent) {
    const sortOn: SortProperty = this.getClickedValue($event);
    this.sortProperty = sortOn;
    this.updateTranslationContext();
  }

  onOrderChange($event: MouseEvent) {
    const sortOn: SortOrder = this.getClickedValue($event);
    this.sortOrder = sortOn;
    this.updateTranslationContext();
  }

  newTranslatorSearchFilter($event: string) {
    if ($event === '') {
      this.currentTranslatorTextFilter = null;
    } else {
      this.currentTranslatorTextFilter = $event;
    }
    this.updateTranslationContext();
  }

  newKeyCreatorKeySearchFilter($event: string) {
    if ($event === '') {
      this.currentKeyCreatorNameFilter = null;
    } else {
      this.currentKeyCreatorNameFilter = $event;
    }
    this.updateTranslationContext();
  }

  setExpandFilterBar(expand: boolean) {
    this.hideThatEnormousFilterBar = !expand;
  }


}
