<p>
  <ng-container *ngIf="isEdit()">
    <a>Id </a><b>{{currentlyEditedLayer.id}}</b>
  </ng-container>
  <ng-container *ngIf="!isEdit()">
    <a>Creating </a> <b>New ModificationLayer</b>
  </ng-container>
</p>
<div>
  <div class="mb-3">
    <label for="formControlInput1" class="form-label">Display Name</label>
    <input type="text" class="form-control" id="formControlInput1" [(ngModel)]="currentlyEditedLayer.displayName"/>
  </div>
  <div class="mb-3">
    <label for="formControlInput2" class="form-label">
      <abbr
        title="The Tag is used as a postfix in all file names containing translations for this modification layer. Like so: 'sv-THIS_TAG.json', 'en-THIS_TAG.json', e.t.c. Preferably kept short, without special characters and spaces.">
         Tag (<i class="fas fa-question" style="font-size: small"></i>)
      </abbr>
    </label>
    <input type="text" class="form-control" id="formControlInput2" [(ngModel)]="currentlyEditedLayer.tag">
  </div>
  <button type="button" class="btn btn-primary" (click)="saveLayer()"
          #saveButton>{{isEdit() ? 'Update' : 'Create'}}</button>
  <br>
  <app-visual-progress [buttonElementToDisable]="saveButton" (onSuccess)="modificationLayersChanged.emit($event)"
                       #visualProcessor></app-visual-progress>
</div>
