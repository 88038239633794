import {Injectable} from '@angular/core';
import {Language} from '../hal/language/language';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, delay, map, retry, retryWhen, share, switchMap, take} from 'rxjs/operators';
import {LanguageService} from '../hal/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class BaseLanguageService {

  private baseLanguage: Language;
  private baseLanguage$: Observable<Language>;

  constructor(private http: HttpClient, languageService: LanguageService) {
    this.baseLanguage$ = this.http.get('/configuration/baseLanguage').pipe(
      switchMap((value: Language) => languageService.getByLanguageCode(value.languageCode)),
      retryWhen(errors => errors.pipe(delay(5000), take(1000))),
      share()
    );

    this.baseLanguage$.subscribe((language: Language) => this.baseLanguage = language);
  }

  private  sleepAndThenRethrowInOrderToDelayNextRetry(error): Observable<Language> {

    throw error;
  }

  public getBaseLanguage(): Promise<Language> {
    if (this.baseLanguage != null) {
      return of(this.baseLanguage).pipe(share()).toPromise();
    }
    return this.baseLanguage$.toPromise();
  }

}
