import {Resource} from '@lagoshny/ngx-hateoas-client';


export abstract class ImprovedResource extends Resource {
  public id: number;
  public createdDate: string;
  public updatedDate: string;
  public updatedBy: string;
  public createdBy: string;
  public latestModificationDate: Date;
  public version: number;

// For some reason all resources gets instantiated as a Resource (their parents parent) and not a Translation. So instance methods does not work.
  public static isExistingInBackend(what: ImprovedResource): boolean {
    return !!what.id;
  }

/*
  public hasRelation(reference: string): boolean {
    return this._links[reference] != null;
  }

  public getRelationUrl(relation: string): string {
    if (!this.hasRelation(relation)) {
      throw new Error('Relation \'' + relation + '\' does not exist on \'' + this + '\'');
    }
    return this._links[relation].href;
  }
*/

 /* public getLatestChangedBy(): string {
    return this.updatedBy == null ? this.createdBy : this.updatedBy;
  }*/

}
