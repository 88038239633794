<div class="container" style="max-width:95%;">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col text-left keyName" style="font-size: larger;">
      <ng-container *ngIf="translationKey$ | async as key">
        <span style=" font-family: fantasy; margin-right:1px;">&ldquo;</span>
        <a>{{key.name}}</a>
        <span style=" font-family: fantasy; margin-left:1px;">&rdquo;</span>
      </ng-container>
    </div>
    <div class="col text-right">
      <app-add-modification-to-layer [language$]="currentLanguage$" [translationKey$]="translationKey$" (modificationLayerCreationRequest)="newModificationLayerRequest($event)"></app-add-modification-to-layer>
      <app-delete-translation-key-dialog [translationKey$]="translationKey$" [showButton]="true"
                                         *ngIf="userMayDeleteKeys"></app-delete-translation-key-dialog>
    </div>
  </div>
  <div class="row">
    <div class="col minimal-padding">
      <app-translations-for-key-and-language #currentLanguageTranslation
                       [language$]="currentLanguage$"
                       [translationKey$]="translationKey$"
                       [triggerRefreshOfTranslationsFromBackend$]="triggerCurrentLanguageTranslationUpdate"
                       (translationWasUpdatedByUserEvent)="currentLanguageTranslationWasUpdatedByUser($event)"></app-translations-for-key-and-language>
    </div>
    <div class="col minimal-padding">
      <app-translations-for-key-and-language [language$]="baseLanguage$"
                       [translationKey$]="translationKey$"
                       [triggerRefreshOfTranslationsFromBackend$]="triggerBaseLanguageTranslationUpdate"
                       (translationWasUpdatedByUserEvent)="baseLanguageTranslationWasUpdatedByUser($event)"></app-translations-for-key-and-language>
    </div>
    <ng-container *ngIf="additionalLanguage$ | async as language">
      <div class="col minimal-padding" *ngIf="language != null">
        <app-translations-for-key-and-language [language$]="additionalLanguage$"
                         [translationKey$]="translationKey$"
                         [triggerRefreshOfTranslationsFromBackend$]="triggerAdditionalLanguageTranslationUpdate"
                         (translationWasUpdatedByUserEvent)="additionalLanguageTranslationWasUpdatedByUser($event)"></app-translations-for-key-and-language>
      </div>
    </ng-container>
  </div>
</div>
