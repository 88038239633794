import {Language} from '../../../services/hal/language/language';
import {Release} from '../../../services/hal/release/release';
import {TranslationStatusFilter} from './translation-status-filter';
import {SortOrder, SortProperty} from './sort-property';
import {TranslationContextLanguages} from './translation-context-languages';

export class TranslationContext {

  constructor(public languages: TranslationContextLanguages,
              public currentlyTranslatingRelease: Release,
              public translationStatusFilter: TranslationStatusFilter,
              public sortOn: SortProperty,
              public sortOrder: SortOrder,
              public keyCreatorFilter: string,
              public latestTranslatorFilter: string,
              public translationTextFilter?: string,
              public translationKeyNameFilter?: string,
              public modificationLayerTagFilter?: string) {

  }

}
