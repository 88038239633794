import {Injectable} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {Translation} from './translation';
import {Language} from '../language/language';
import {TranslationKey} from '../translationkey/translation-key';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Release} from '../release/release';
import {ModificationLayer} from '../modificationlayer/modification-layer';
import {HateoasResourceService} from '@lagoshny/ngx-hateoas-client';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends ImprovedRestService<Translation> {


  constructor(private httpClient: HttpClient, private rs: HateoasResourceService) {
    super(Translation);
  }

  public getForKeyAndLanguage(key: TranslationKey, language: Language): Observable<Translation[]> {
    return this.rs.searchCollection(Translation, 'findByTranslationKeyAndLanguage',
      {
        params:
          {
            language: language.getSelfLinkHref(),
            translationKey: key.getSelfLinkHref()
          }
      }
    ).pipe(map((value) => value.resources));
  }

  public async createOrUpdateTranslation(translation: Translation): Promise<any> {
    if (translation.id) {
      return this.updateTranslation(translation);
    } else {
      return this.createTranslation(translation);
    }
  }

  public async createTranslation(translation: Translation): Promise<any> {
    if (translation.id) {
      throw new Error('Cannot create a translation that already exists.');
    }
    if (!translation.translationKey) {
      throw new Error('There were no key associated to the new Translation');
    }
    if (!translation.sinceRelease) {
      throw new Error('There were no Since-Release associated to the new Translation');
    }
    if (!translation.language) {
      throw new Error('There were no Language associated to the new Translation');
    }

    return this.doCreateOrUpdate(translation.translationKey, translation, translation.sinceRelease, translation.language, translation.modificationLayer);
  }

  public async updateTranslation(translation: Translation): Promise<any> {
    if (!translation.id) {
      throw new Error('Cannot update a translation that does not have an Id.');
    }
    const sinceRelease = await Translation.getSinceRelease(translation).toPromise();
    const language = await Translation.getLanguage(translation).toPromise();
    const translationKey = await Translation.getTranslationKey(translation).toPromise();
    const modificationLayer = await Translation.getModificationLayer(translation).toPromise();
    console.log('ModificationLayer @PersistTime: ' + modificationLayer ? 'null' : modificationLayer.tag);

    return this.doCreateOrUpdate(translationKey, translation, sinceRelease, language, modificationLayer);
  }

  private doCreateOrUpdate(translationKey: TranslationKey, translation: Translation, sinceRelease: Release, language: Language, modificationLayer: ModificationLayer) {
    const translationMap: Map<string, string> = new Map();
    translationMap[translationKey.name] = translation.translation;
    return this.updateTranslations(sinceRelease, language, translationMap, modificationLayer).toPromise();
  }

  public updateTranslations(sinceRelease: Release, language: Language, translationMap: Map<string, string>, modificationLayer?: ModificationLayer) {
    return this.httpClient.post('/translationSink/pour?release=' + sinceRelease.releaseName + '&language=' + language.languageCode + (modificationLayer ? '&modificationLayer=' + modificationLayer.tag : ''), translationMap);
  }
}
