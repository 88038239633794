<div class="container-fluid">
  <div class="row">
    <div class="col text-center">
      <h1>Language Configuration</h1>
      <app-language-configuration></app-language-configuration>
    </div>
    <div class="col text-center">
      <h1>Modification Layer Configuration</h1>
      <app-modification-layer-configuration></app-modification-layer-configuration>
    </div>
  </div>
</div>

