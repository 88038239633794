import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {LanguageService} from '../../../../services/hal/language/language.service';
import {Language} from '../../../../services/hal/language/language';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {LanguageVisalizationComponent} from '../language-visalization/language-visalization.component';
import {map, shareReplay, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.css']
})
export class LanguageListComponent implements OnInit, AfterViewInit, OnDestroy {

  languages$: Observable<Language[]>;
  @Output() languageSelectedEvent: EventEmitter<Language> = new EventEmitter();
  @Input() text = 'Select Language: ';
  @Input() $selectedLanguageCode?: Observable<string>;
  @ViewChildren('languageComponent') languageComponents: LanguageVisalizationComponent[];

  private highlightlingSubscription: Subscription;
  private currentlySelectedCodeSubscription: Subscription;

  private languageChangedSubject: Subject<Language> = new ReplaySubject(1);

  private languageChangedSubscription: Subscription;
  constructor(private languageService: LanguageService) {
  }

  ngOnInit() {
    this.languages$ = this.languageService.getAll().pipe(shareReplay(1));



    if (!this.$selectedLanguageCode) {
      this.$selectedLanguageCode = this.languageChangedSubject.pipe(map(language => language.languageCode), shareReplay(1));
    }

    this.languageChangedSubscription = this.languageChangedSubject.subscribe(newLanguage => this.languageSelectedEvent.emit(newLanguage));

    this.highlightlingSubscription = this.languages$.pipe(
      switchMap(() => this.$selectedLanguageCode),
    ).subscribe(countryCode => this.setHighlightedLanguageByCode(countryCode));
  }

  onLanguageSelected($event: Language) {
    this.languageChangedSubject.next($event);
  }

  private setHighlightedLanguageByCode(selectedLanguageCode: string) {
    setTimeout(() =>
      this.languageComponents.forEach(
        component => component.highlightIfRepresents(selectedLanguageCode)
      )
    );
  }

  ngAfterViewInit(): void {
    this.currentlySelectedCodeSubscription = this.$selectedLanguageCode.subscribe(
      next => this.setHighlightedLanguageByCode(next)
    );
  }

  ngOnDestroy(): void {
    this.currentlySelectedCodeSubscription.unsubscribe();
    this.highlightlingSubscription.unsubscribe();
    this.languageChangedSubscription.unsubscribe();
  }

}
