import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {Language} from '../../../services/hal/language/language';
import {LanguageService} from '../../../services/hal/language/language.service';
import {TranslationKeyService} from '../../../services/hal/translationkey/translation-key.service';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {TranslationKey} from '../../../services/hal/translationkey/translation-key';
import {TranslationContext} from '../../page/translate/translation-context';
import {TranslationKeyComponent} from '../translation-key/translation-key/translation-key.component';
import {TranslationStatusFilter} from '../../page/translate/translation-status-filter';
import {SortOrder, SortProperty} from '../../page/translate/sort-property';
import {TranslationContextLanguages} from '../../page/translate/translation-context-languages';
import {PaginationData} from './pagination-data';
import {PagedResourceCollection} from '@lagoshny/ngx-hateoas-client';
import {TranslationEventDto} from '../translation-key/translation-key/translation-event-dto';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']

})
export class LanguageComponent implements OnInit, OnDestroy {

  @Input() translationContext$: Observable<TranslationContext>;
  @Input() jumpToNextUntranslatedAfterSaving$: Observable<boolean>;
  @Input() jumpToNextUntranslatedAfterSavingOnlyWhenUntranslated$: Observable<boolean>;
  @Output() onJumpingToNextTranslationAfterSave: EventEmitter<void> = new EventEmitter();
  paginationData$: Subject<PaginationData> = new ReplaySubject(1);
  shouldJumpToNextTranslationOnSave: boolean;
  shouldJumpOnlyWhenUntranslated: boolean;
  currentLanguage$: Observable<Language>;
  additionalLanguage$: Observable<Language>;
  allTranslationKeys$: Observable<TranslationKey[]>;
  resourceCollection$: Observable<PagedResourceCollection<TranslationKey>>;
  translationContextLanguages$: Observable<TranslationContextLanguages>;
  @ViewChildren('translationKeyElement') currentKeys: TranslationKeyComponent[];
  @ViewChild('search') search: ElementRef<HTMLInputElement>;


  shouldJumpSubscription: Subscription;
  shouldJumpOnlyIfUntranslatedSubscription: Subscription;
  resetPaginationOnContextUpdateSubscription: Subscription;

  pageNumber = 1; // The component is 1-indexed, but the backend is 0-indexed
  pageSize = 5;

  constructor(private languageService: LanguageService, private translationKeyService: TranslationKeyService) {

  }

  ngOnInit() {
    this.resetPaginationOnContextUpdateSubscription = this.translationContext$.subscribe(context => this.resetPage());

    this.resourceCollection$ = combineLatest([this.translationContext$, this.paginationData$]).pipe(
      switchMap(contextAndPaginationData => this.getDesiredTranslationKeys(contextAndPaginationData[0], contextAndPaginationData[1])),
      shareReplay(1)
    );

    this.allTranslationKeys$ = this.resourceCollection$.pipe(
      map(collection => collection.resources),
      shareReplay(1)
    );

    this.translationContextLanguages$ = this.translationContext$.pipe(map(context => context.languages), shareReplay(1));

    this.currentLanguage$ = this.translationContext$.pipe(
      map(context => context.languages.currentlyTranslatingLanguage),
      shareReplay(1)
    );

    this.additionalLanguage$ = this.translationContext$.pipe(
      filter(context => context.languages.additionalLanguage != null),
      map(context => context.languages.additionalLanguage)
    );

    this.shouldJumpSubscription = this.jumpToNextUntranslatedAfterSaving$.subscribe(
      newValue => {
        this.shouldJumpToNextTranslationOnSave = newValue;
      }
    );

    this.shouldJumpOnlyIfUntranslatedSubscription = this.jumpToNextUntranslatedAfterSavingOnlyWhenUntranslated$.subscribe(
      newValue => {
        this.shouldJumpOnlyWhenUntranslated = newValue;
      }
    );

    this.updatePaginationDataFromCurrentState();
  }

  private updatePaginationDataFromCurrentState() {
    this.paginationData$.next(new PaginationData(this.pageNumber, this.pageSize));
  }


  private resetPage() {
    this.setPageNumber(1);
  }

  getDesiredTranslationKeys(context: TranslationContext, paginationData: PaginationData): Observable<PagedResourceCollection<TranslationKey>> {
    const translationStatus: boolean = this.mapTranslationStatusFilterToQueryString(context.translationStatusFilter);
    const sortPropertyPath = this.mapSortPropertyToPath(context.sortOn);
    const sortOrder = this.mapSortOrderToShortString(context.sortOrder);

    return this.translationKeyService.searchBySpecification(
      context.currentlyTranslatingRelease,
      translationStatus,
      context.translationTextFilter,
      context.translationKeyNameFilter,
      context.languages.currentlyTranslatingLanguage.languageCode,
      context.latestTranslatorFilter,
      context.keyCreatorFilter,
      context.modificationLayerTagFilter,
      sortPropertyPath,
      sortOrder,
      paginationData.pageSize,
      paginationData.pageNumber - 1
    );
    /* if (!context.onlyShowUntranslated) {
       return this.translationKeyService.getAllForRelease(context.currentlyTranslatingRelease);
     }
     return this.translationKeyService.getAllKeysWhichAreUntranslatedForReleaseAndLanguage(context.currentlyTranslatingRelease, context.currentlyTranslatingLanguage);*/
  }

  translationUpdatedForCurrentLanguage(translationEvent: TranslationEventDto) {
    if (!this.shouldJumpToNextTranslationOnSave) {
      return;
    }
    console.log(translationEvent);
    if (this.shouldJumpOnlyWhenUntranslated && !translationEvent.wasPreviouslyUntranslated) {
      return;
    }
    this.onJumpingToNextTranslationAfterSave.next(null);
    this.jumpToNextSwitchPageRecursive(true);
  }

  private jumpToNextSwitchPageRecursive(allowSwitchingPage: boolean) {
    const untranslatedElseUndefined = this.currentKeys.find(current => !current.isCurrentLanguageTranslated());
    if (untranslatedElseUndefined === undefined) {
      if (allowSwitchingPage) {
        this.setPageNumber(this.pageNumber + 1);
      }
      setTimeout(() => this.jumpToNextSwitchPageRecursive(false), 1500);
    } else {
      untranslatedElseUndefined.enterEditModeForCurrentLanguageTranslation();
    }
  }

  ngOnDestroy(): void {
    this.shouldJumpSubscription.unsubscribe();
    this.shouldJumpOnlyIfUntranslatedSubscription.unsubscribe();
    this.resetPaginationOnContextUpdateSubscription.unsubscribe();
  }

  private mapTranslationStatusFilterToQueryString(translationStatusFilter: TranslationStatusFilter) {
    switch (translationStatusFilter) {
      case 'TRANSLATED':
        return true;
      case 'UNTRANSLATED':
        return false;
      case 'ALL':
        return null;
      default:
        throw new Error('Unknown Translation Status ' + translationStatusFilter);
    }
  }

  setPageSize(newSize: number) {
    this.pageSize = newSize;
    this.updatePaginationDataFromCurrentState();
  }

  setPageNumber(newPageNumber: number) {
    this.pageNumber = newPageNumber;
    this.updatePaginationDataFromCurrentState();
  }

  private mapSortPropertyToPath(sortOn: SortProperty) {
    switch (sortOn) {
      case 'Key Created Date':
        return 'createdDate';
      case 'Key Name':
        return 'name';
      case 'Key Created By':
        return 'createdBy';

      default:
        throw new Error('Unknown enum value ' + sortOn);
    }
  }

  private mapSortOrderToShortString(order: SortOrder) {
    switch (order) {
      case 'Ascending':
        return 'ASC';
      case 'Descending':
        return 'DESC';
      default:
        throw new Error('Unknown enum value ' + order);
    }
  }
}
