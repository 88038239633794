import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../../../services/domain/user/user';
import {Observable, of} from 'rxjs';
import {UserService} from '../../../../../services/domain/user/user.service';

@Component({
  selector: '[app-user]',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  @Input() user: User;
  $user: Observable<User>;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.$user = of(this.user);
  }

  deleteUser() {
    if (confirm('Are you sure that you want to drop this "' + this.user.username + '"?')) {
      this.userService.deleteUser(this.user).subscribe(
        next => alert('It has been done. ' + this.user.realName + ' is no more.'),
        error => alert('The job went to shit the moment the hit started: ' + JSON.stringify(error))
      );
    }
  }
}
