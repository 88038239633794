<div class="container-fluid">
  <div class="row text-left">
    <div class="col-3 text-left">
      <a class="" [innerHTML]="text"></a>
    </div>
    <div class="col text-left">
    <ng-container *ngFor="let language of (languages$ | async)" class="text-left" >
         <app-language-visalization #languageComponent [language]="language"
                                     (clickedEvent)="onLanguageSelected($event)" style="margin-left: 5px"></app-language-visalization>
    </ng-container>
    </div>
  </div>
</div>
