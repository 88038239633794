<div class="container text-left">
  <div class="row">
    <div class="col" style="margin-right:50px">
      <h4>Visual Studio Code Extension</h4>
      <p>Download latest extension version: <a href="/plugin/deswinglisher-integration-LATEST.vsix">Here</a></p>
      <div>
        <h5>Installation</h5>
        <p>Select one of the two installation methods below of your choice.</p>
        <h6>Method #1: </h6>
        <p>To install, execute the following command in the terminal, and then restart VSCode:<br>
          <code>code --install-extension .\deswinglisher-integration-LATEST.vsix</code><br></p>
        <h6>Method #2: </h6>
        <p>
          <a>Inside VSCode, navigate to <i>Extensions</i><b>&rarr;</b><i>Menu ("...")</i><b>&rarr;</b><i>Install from VSIX</i></a>
        </p>
      </div>
      <div>
        <h5>Activation</h5>
        <p>To activate the extension for a new workspace:</p>
        <ol>
          <li>Make sure you are in a workspace.</li>
          <li>Use the command palette (<i>CTRL + SHIFT + P</i> by default) to execute the command <i>Integrate Workspace with
            DeSWINGLISHER</i>.
          </li>
          <li>Follow the instructions and alter any extension settings required to communicate with the
            DeSWINGLISHER-server.
          </li>
        </ol>
      </div>
      <div>
        <h5>For information about features, changelog, e.t.c.</h5>
        <p>Look in VSCode under the <i>Extensions tab</i><b>&rarr;</b><i>Menu ("...")</i><b>&rarr;</b><i>Show Installed Extensions</i><b>&rarr;</b><i>DeSWINGLISHER
          Integration</i></p>
      </div>
    </div>
    <div class="col" style="margin-left:50px">
      <h4>IntelliJ IDEA & Webstorm Plugin</h4>
      <p>To install the plugin and to automatically keep it up to date, add the DeSWINGLISHER-server:s url extended with
        the path "/plugin/updatePlugins.xml", to IntelliJ/Webstorm as a PluginRepository.<br>
        <b>Repository URL: </b> <code>https://deswinglisher.quedro.com/plugin/updatePlugins.xml</code>
      </p>

      <div>
        <h5>Step by step installation:</h5>
        <ol>
          <li>In IntelliJ or Webstorm, open settings (CTRL + ALT + S by default).</li>
          <li>Navigate to <i>Plugins</i> and press the <i>Gear-Icon</i>.</li>
          <li>Select <i>Manage plugin repositories...</i>.</li>
          <li>Click <i>+</i> and enter the above Repository URL.</li>
          <li>Close the repositories-window and search for <i>Quedro DeSWINGLISHER Integration</i></li>
          <li>Install it.</li>
          <li>Glorious IntelliJ-magic will<sup><b>*</b></sup> remind you when there is an update available. For usage instructions: ask a bro, use the customary trial-and-horror-approach, or look at this <a href="/information/informationalVideo" target="_blank">instructional video.</a></li>
        </ol>
        <small><sup><b>*</b></sup> = sometimes</small>
      </div>
    </div>
  </div>
</div>
