import {Translation} from '../../../../services/hal/translation/translation';
import {Release} from '../../../../services/hal/release/release';
import {Language} from '../../../../services/hal/language/language';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {Observable, of} from 'rxjs';

export class TranslationCreationContext {
  constructor(private suggestedTranslation: string, private sinceRelease: Release, private language: Language, private translationKey: TranslationKey, private modificationLayer?: ModificationLayer) {

  }

  public getLanguageObservable(): Observable<Language> {
    return of(this.language);
  }
  public getTranslationKeyObservable(): Observable<TranslationKey> {
    return of(this.translationKey);
  }


  public getSuggestedTranslation(): Translation {
    const translation = new Translation();
    translation.translationKey = this.translationKey;
    translation.language = this.language;
    translation.sinceRelease = this.sinceRelease;
    translation.modificationLayer = this.modificationLayer;
    translation.forModificationLayer = !!this.modificationLayer ;

    translation.translation = this.suggestedTranslation;
    translation.translated = false;

    return translation;
  }

}
