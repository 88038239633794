<nav class="navbar navbar-expand-xl navbar-dark bg-dark" style="margin-bottom: 15px" *ngIf="!hideMenu">

  <div class="container-fluid justify-content-center">
    <div class="row justify-content-center" style="width:100%">
      <div class="col col-sm-auto align-self-start" style="color:white; font-size:32px; font-family: Arial,serif;">
        <div style="margin: 0px; line-height:1;">
          <a href="#"><img src="assets/de-swinglisher-trimmed.png" class="logo-shadow"
                           style="vertical-align: middle; max-height: 40px;"></a>
        </div>
      </div>
      <div class="text-center">
        <small *ngIf="$version | async as version" class="text-light">{{version.version}}</small>
      </div>
      <div class="col align-self-center">
        <ul class="navbar-nav mr-auto justify-content-center increase-padding">
          <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="overview">Home</a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="translate">Translate</a></li>
          <ng-container *ngIf="($user | async) as user">
            <ng-container *ngIf="user.authorities.hasRole('ADMIN')">
              <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="pour">Pour Translations</a>
              </li>
              <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="languages">Languages</a>
              </li>
              <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="modificationLayers">Modification
                Layers</a></li>
              <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="user">Manage Users</a></li>
              <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="development">Developer</a>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
      <div class="col col-sm-auto  align-self-end">
        <ng-container *ngIf="($user | async) as user">
          <ng-container *ngIf="user.authorities.hasRole('ADMIN')">
            <div class="container">
              <div class="row">
                <div class="col">
                  <app-quick-create-translation-key></app-quick-create-translation-key>
                </div>
                <div class="col">
                  <app-triggerable-url-manager-button></app-triggerable-url-manager-button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</nav>
