import {Language} from '../../../../services/hal/language/language';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';

export class ModificationCreationRequest {
  constructor(public language: Language,
              public key: TranslationKey,
              public layer: ModificationLayer) {
  }


}
