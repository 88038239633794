<h3>Modification Layers</h3>

<button class="btn btn-sm btn-outline-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
  <i class="fas fa-question"></i> Information
</button>
<div class="collapse" id="collapseExample">
  <div class="card card-body">
    <p>
      The ModificationLayer concept is a complement to the language concept. It allows for the overriding of specific translations for select customers by tagging those customers with one or more ModificationLayer(s).<br>
      The translations contained in the ModificationLayer, the <i>ModificationLayerTranslations</i>, will take precedence over the <i>BaseTranslations</i> (the original translation for a key and language).
    </p>
    <ul>
      <li>A translation for a specific ModificationLayer can be created for a any combination of Language and TranslationKey and is called a <i>ModificationLayerTranslation</i>.</li>
      <li>The ModificationLayerTranslation always coexists with the <i>BaseTranslation</i> (the original translation for a given language and TranslationKey).</li>
      <li>All ModificationLayerTranslations for a given language will end up in a separate file marked with the LanguageCode and the ModificationLayers TAG-attribute. For example, all ModificationLayerTranslations for the language named "Swedish" and the ModificationLayer named "Nyproduktion" will end up in the file "sv-NYPROD.json" (where sv is the LanguageCode and NYPROD the Tag).</li>
      <li>In CRM, the customer may be tagged with [0..*] ModificationLayers in the Settings.json file.</li>
      <li>If in CRM, the customer has been tagged with a ModificationLayer, for example NYPROD, any translations found in "sv-NYPROD.json" will override the BaseTranslations found in "sv.json".</li>
      <li>Thus all layers chosen for this customer will take precedence over the BaseTranslations and will render a different end-result for a customer tagged with a ModificationLayer compared to one without, given that there are any ModificationLayerTranslations for the customers language.</li>
    </ul>
  </div>
</div>

<div class="card" style="margin-top: 50px">
  <div class="card-body">
    <div class="container-fluent">
      <div class="row">
        <div class="col-10">
          <app-modification-layer-list #modificationLayerList
                                       [text]="'Edit Layer: '" (layerSelectedEvent)="layerSelected$.next($event)">
          </app-modification-layer-list>
        </div>
        <div class="col-2">
          <button type="button" class="btn btn-primary" (click)="createNewLayer()">+ New ModificationLayer</button>
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="layerSelected$ | async as language" class="card" style="margin: 20px">
    <div class="card-body">
      <h3><small class="text-muted" style="margin-right:15px">ModificationLayer</small>
        <app-modification-layer-visualization
          [modificationLayer$]="layerSelected$"></app-modification-layer-visualization>
      </h3>

      <div class="text-left">
        <app-modification-layer-editor [editedLayer$]="layerSelected$" (modificationLayersChanged)="modificationLayerList.refreshContent()"></app-modification-layer-editor>
      </div>
    </div>
  </div>
</div>

