import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Language} from '../../../../../services/hal/language/language';
import {TranslationService} from '../../../../../services/hal/translation/translation.service';
import {ReleaseService} from '../../../../../services/hal/release/release.service';
import {ModificationLayer} from '../../../../../services/hal/modificationlayer/modification-layer';


@Component({
  selector: 'app-the-sink',
  templateUrl: './the-sink.component.html',
  styleUrls: ['./the-sink.component.css']
})
export class TheSinkComponent implements OnInit, OnDestroy {
  @ViewChild('theSink') theSink: ElementRef<HTMLTextAreaElement>;

  @Input() language$: Observable<Language>;

  @Input() modificationLayer$: Observable<ModificationLayer | null>;
  protected DEFAULT_TEXT_AREA_VALUE = '{\n\t"" : ""\n}\n';

  activity: boolean;

  displayDoneNotification: boolean;
  currentModificationLayer?: ModificationLayer;

  private modificationLayerSubscription: Subscription;

  constructor(private translationService: TranslationService, private releaseService: ReleaseService) {
  }

  ngOnInit() {
    this.modificationLayerSubscription = this.modificationLayer$.subscribe(newLayerOrNull => this.currentModificationLayer = newLayerOrNull);
  }

  userRequestsClearing() {
    this.theSink.nativeElement.value = this.DEFAULT_TEXT_AREA_VALUE;
  }

  pourTranslations(language: Language) {

    let translationMap = null;
    try {
      translationMap = JSON.parse(this.theSink.nativeElement.value);
    } catch (e) {
      alert('Din JSON sög: ' + e);
      return;
    }
    this.setStateToPouring();
    try {
      this.pour(translationMap, language, this.currentModificationLayer);
    } catch (e) {
      this.setStateToDone();
    }

  }

  private async pour(translationMap, language: Language, modificationLayer?: ModificationLayer) {
    const release = await this.releaseService.getLatestRelease().toPromise();
    this.translationService.updateTranslations(release, language, translationMap, modificationLayer).toPromise()
      .then(() => this.setStateToDone())
      .catch((error) => this.setStateToFailed(error))
      .finally(() => this.setStateToDone());
  }

  setStateToPouring() {
    this.activity = true;
  }

  setStateToDone() {
    this.activity = false;
    this.displayDoneNotification = true;
    setTimeout(() => this.displayDoneNotification = false, 2400);
  }

  private setStateToFailed(error: any) {
    this.setStateToDone();
    alert('Det där sket sig rejält: ' + error.message);
  }

  ngOnDestroy(): void {
    this.modificationLayerSubscription.unsubscribe();
  }
}
