import {ImprovedResource} from '../../improved-resource';
import {Release} from '../release/release';
import {HateoasResource} from '@lagoshny/ngx-hateoas-client';
@HateoasResource('translationKeys')
export class TranslationKey extends ImprovedResource {
  name: string;
  sinceRelease: Release;
  desistedInRelease: Release;
  replacedBy: number;

}
