<ng-container *ngIf="(translations$ | async) as translationList">
  <ng-container *ngFor="let translation of translationList">
    <app-translation #translationComponents
                     [language$]="language$"
                     [translationKey$]="translationKey$"
                     [translation]="translation"
                     (translationWasUpdatedByUserEvent)="translationWasUpdatedByUser($event)"
                     (modificationLayerTranslationDeletedByUser)="translationWasDeletedByUser($event)"></app-translation>
  </ng-container>
</ng-container>

<ng-container *ngIf="translationCreationContext">
  <app-translation [language$]="translationCreationContext.getLanguageObservable()"
                   [translationKey$]="translationCreationContext.getTranslationKeyObservable()"
                   [translation]="translationCreationContext.getSuggestedTranslation()"
                   (translationWasUpdatedByUserEvent)="additionalTranslationCreatedByUser($event)">

  </app-translation>
</ng-container>

