import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delay, retryWhen, share, switchMap, take} from 'rxjs/operators';
import {Language} from '../hal/language/language';
import {Observable, of} from 'rxjs';
import {VersionInformation} from './version-information';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  versionInformation$: Observable<VersionInformation>;
  versionInformation: VersionInformation;

  constructor(private httpClient: HttpClient) {
    this.versionInformation$ = this.httpClient.get('/configuration/version').pipe(
      retryWhen(errors => errors.pipe(delay(5000), take(1000))),
      share()
    ) as Observable<VersionInformation>;

    this.versionInformation$.subscribe((versionInformation: VersionInformation) => this.versionInformation = versionInformation);
  }

  public getVersion(): Observable<VersionInformation> {
    if (this.versionInformation != null) {
      return of(this.versionInformation).pipe(share());
    }
    return this.versionInformation$;
  }
}
