import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {MenuComponent} from './components/menu/menu.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {DateAgoPipe} from './utility/date-ago.pipe';
import {OverviewComponent} from './components/page/overview/overview.component';
import {LanguageComponent} from './components/translationdomain/language/language.component';
import {LanguageVisalizationComponent} from './components/translationdomain/language/language-visalization/language-visalization.component';
import {TranslateComponent} from './components/page/translate/translate.component';
import {LanguageListComponent} from './components/translationdomain/language/language-list/language-list.component';
import {NgxHateoasClientConfigurationService, NgxHateoasClientModule} from '@lagoshny/ngx-hateoas-client';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslationKeyInfoComponent} from './components/translationdomain/translation-key/translation-key-info/translation-key-info.component';
import {TranslationKeyComponent} from './components/translationdomain/translation-key/translation-key/translation-key.component';
import {TranslationComponent} from './components/translationdomain/translation/translation/translation.component';
import {PourTranslationsComponent} from './components/page/pour/pour-translations/pour-translations.component';
import {TheSinkComponent} from './components/page/pour/pour-translations/the-sink/the-sink.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {UserPageComponent} from './components/page/user/user-page/user-page.component';
import {UserComponent} from './components/page/user/user-page/user/user.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputDebounceComponent} from './components/controls/input-debounce/input-debounce.component';
import {IntegrationPluginsComponent} from './components/page/development-page/integration-plugins/integration-plugins.component';
import {DevelopmentPageComponent} from './components/page/development-page/development-page.component';
import {QuickCreateTranslationKeyComponent} from './components/menu/quick-create-translation-key/quick-create-translation-key.component';
import {DeleteTranslationKeyDialogComponent} from './components/translationdomain/translation-key/delete-translation-key-dialog/delete-translation-key-dialog.component';
import {TriggerableUrlComponent} from './components/buildecosystem/triggerable-url/triggerable-url.component';
import {TriggerableUrlManagerComponent} from './components/buildecosystem/triggerable-url-manager/triggerable-url-manager.component';
import {TriggerableUrlManagerButtonComponent} from './components/buildecosystem/triggerable-url-manager-button/triggerable-url-manager-button.component';
import {TranslationsForKeyAndLanguageComponent} from './components/translationdomain/translation-key/translations-for-key-and-language/translations-for-key-and-language.component';
import {LanguageAndModificationLayerConfigurationPageComponent} from './components/page/language-and-modification-layer-configuration-page/language-and-modification-layer-configuration-page.component';
import {LanguageConfigurationComponent} from './components/page/language-and-modification-layer-configuration-page/language-configuration/language-configuration.component';
import {ModificationLayerConfigurationComponent} from './components/page/language-and-modification-layer-configuration-page/modification-layer-configuration/modification-layer-configuration.component';
import {LanguageEditorComponent} from './components/translationdomain/language/language-editor/language-editor.component';
import {VisualProgressComponent} from './components/controls/visual-progress/visual-progress.component';
import {ModificationLayerVisualizationComponent} from './components/translationdomain/modificationlayer/modification-layer-visualization/modification-layer-visualization.component';
import {ModificationLayerListComponent} from './components/translationdomain/modificationlayer/modification-layer-list/modification-layer-list.component';
import {ModificationLayerEditorComponent} from './components/translationdomain/modificationlayer/modification-layer-editor/modification-layer-editor.component';
import {AddModificationToLayerComponent} from './components/translationdomain/translation-key/add-modification-to-layer/add-modification-to-layer.component';
import {PipesModule} from './utility/filter/pipes.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {Translation} from './services/hal/translation/translation';
import {TranslationKey} from './services/hal/translationkey/translation-key';
import {Language} from './services/hal/language/language';
import {Release} from './services/hal/release/release';
import {TriggerableUrl} from './services/hal/triggerable-url/triggerable-url';
import {ModificationLayer} from './services/hal/modificationlayer/modification-layer';
import { HtmlEditorComponent } from './components/translationdomain/translation/translation/html-editor/html-editor.component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import { KeyTranslationWidgetComponent } from './components/page/key-translation-widget/key-translation-widget.component';

@NgModule({ declarations: [
        AppComponent,
        MenuComponent,
        OverviewComponent,
        LanguageComponent,
        LanguageVisalizationComponent,
        TranslateComponent,
        LanguageListComponent,
        TranslationKeyInfoComponent,
        TranslationKeyComponent,
        TranslationComponent,
        PourTranslationsComponent,
        TheSinkComponent,
        UserPageComponent,
        UserComponent,
        InputDebounceComponent,
        IntegrationPluginsComponent,
        DevelopmentPageComponent,
        QuickCreateTranslationKeyComponent,
        DeleteTranslationKeyDialogComponent,
        TriggerableUrlComponent,
        TriggerableUrlManagerComponent,
        TriggerableUrlManagerButtonComponent,
        TranslationsForKeyAndLanguageComponent,
        LanguageAndModificationLayerConfigurationPageComponent,
        LanguageConfigurationComponent,
        ModificationLayerConfigurationComponent,
        LanguageEditorComponent,
        VisualProgressComponent,
        ModificationLayerVisualizationComponent,
        ModificationLayerListComponent,
        ModificationLayerEditorComponent,
        AddModificationToLayerComponent,
        HtmlEditorComponent,
        KeyTranslationWidgetComponent,
        DateAgoPipe
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        /**
         * TODO after 17:
         * Make sure you configure setupTestingRouter, canceledNavigationResolution, paramsInheritanceStrategy, titleStrategy, urlUpdateStrategy, urlHandlingStrategy, and malformedUriErrorHandler in provideRouter or RouterModule.forRoot since these properties are now not part of the Router's public API
         */
        RouterModule,
        AppRoutingModule,
        NgxHateoasClientModule.forRoot(),
        CommonModule,
        FormsModule,
        EditorModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        PipesModule,
        BrowserAnimationsModule], providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
        // {provide: 'ExternalConfigurationService', useClass: ExternalConfigurationService}
        ,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
  constructor(hateoasConfig: NgxHateoasClientConfigurationService) {
    hateoasConfig.configure({
      useTypes: {
        resources: [Translation, TranslationKey, Language, Release, TriggerableUrl, ModificationLayer]
      },
      cache: {
        enabled: false
      },
      http: {
        rootUrl: '/'
      }
    });
  }
}
