import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {forkJoin, Observable} from 'rxjs';
import {UserService} from '../../../../services/domain/user/user.service';
import {TranslationKeyService} from '../../../../services/hal/translationkey/translation-key.service';
import {ReleaseService} from '../../../../services/hal/release/release.service';
import {flatMap} from 'rxjs/operators';

@Component({
  selector: 'app-delete-translation-key-dialog',
  templateUrl: './delete-translation-key-dialog.component.html',
  styleUrls: ['./delete-translation-key-dialog.component.css']
})
export class DeleteTranslationKeyDialogComponent implements OnInit {

  @Input()
  translationKey$: Observable<TranslationKey>;

  @Input()
  showButton: boolean;

  loadImages: boolean;

  @ViewChild('modal') modal: ElementRef;
  @ViewChild('showButtonElement') showButtonElement: ElementRef;

  constructor(private userService: UserService, private translationKeyService: TranslationKeyService, private releaseService: ReleaseService) {
  }

  ngOnInit() {

  }


  desistTranslationKey($event: MouseEvent) {
    forkJoin(this.translationKey$, this.releaseService.getLatestRelease()).pipe(
      flatMap(([key, release]) => this.translationKeyService.desistByNameAndRelease(key, release))
    ).toPromise()
      .then((deletedKey) => {
        window.location.reload();
      })
      .catch((error) => alert('There was a significant explosion while trying to desist your translation key: ' + error.message));
  }

  public popup() {
    // @ts-ignore
    showButtonElement.nativeElement.click();
  }

}
