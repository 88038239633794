import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/domain/user/user.service';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from '../../../../services/domain/user/user';
import {LanguageService} from '../../../../services/hal/language/language.service';
import {Language} from '../../../../services/hal/language/language';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {

  $allUsers: Observable<User[]>;
  $allLanguages: Observable<Language[]>;

  registerUserForm: UntypedFormGroup;
  $transl: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private userService: UserService, private languageService: LanguageService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.$transl.next(false);
    this.$allUsers = this.userService.getAllUsers();
    this.$allLanguages = this.languageService.getAll();

    this.registerUserForm = this.formBuilder.group({
        username: ['', Validators.required],
        realName: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        role: ['ADMIN', Validators.required],
        language: ['']
      },
      {});

  }

  onSubmit() {
    if (this.registerUserForm.invalid) {
      alert('You have to fix your shit: ' + this.registerUserForm.errors);
      return;
    }

    const value = this.registerUserForm.value;
    let result: Observable<any>;
    if (value.role === 'TRANSLATOR') {
      result = this.userService.createNewTranslator(value);
    } else if (value.role === 'ADMIN') {
      result = this.userService.createNewAdmin(value);
    }

    result.toPromise()
      .then(next => this.onUserCreated(value))
      .catch(error => alert('THAT WENT STRAIGHT TO SHIT: ' + error.message));
  }

  private onUserCreated(whom) {
    alert('Sir. It has been done. Today, we welcome ' + whom.realName + ' into the (not so) wonderful world of DeSWINGLISHER.');
    this.refreshUserList();
  }

  private refreshUserList() {
    this.$allUsers = this.userService.getAllUsers();
  }
}
