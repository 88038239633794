import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.css']
})
export class HtmlEditorComponent implements OnInit, OnDestroy {


  @Input() currentText: string;
  @Output() htmlOutput: EventEmitter<string> = new EventEmitter<string>();



  doPutEditorInFocus(){

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  onChange(event: string) {
    this.htmlOutput.emit(event);
  }
}
