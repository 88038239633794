import {GetOption, HateoasResourceOperation, HttpMethod, PagedGetOption, PagedResourceCollection, Resource} from '@lagoshny/ngx-hateoas-client';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ResourceCollection} from '@lagoshny/ngx-hateoas-client/lib/model/resource/resource-collection';
import {BaseResource} from '@lagoshny/ngx-hateoas-client/lib/model/resource/base-resource';

export class ImprovedRestService<T extends Resource> extends HateoasResourceOperation<T> {

  public getAll(): Observable<T[]> {
    return super.getPage().pipe(
      catchError(this.mapAnyTypeErrorToInsteadReturnEmptyList__TheRetardedFrameworkDoesNotFollowTheContractOfAStandardApi),
      map((value: PagedResourceCollection<T>) => value.resources)
    );
  }

  private mapAnyTypeErrorToInsteadReturnEmptyList__TheRetardedFrameworkDoesNotFollowTheContractOfAStandardApi(error: any, caught: Observable<any>) {
    if (error instanceof TypeError) {
      return of([]);
    }
    console.log('An error occurred in ' + typeof (this) + ': ');
    console.log(error);
    throw error;
  }

  // This is to remain backwards compatible with the old way of calling the RestService until we take the time to rewrite everything.

  public update(entity: T): Observable<T> {
    return this.updateResource(entity, {body: entity});
  }

  public search(searchMethodName: string, options?: GetOption): Observable<T[]> {
    return super.searchCollection(searchMethodName, options).pipe(
      map((value: ResourceCollection<T>) => value.resources)
    );
  }

  public searchSingle(searchMethodName: string, options?: GetOption): Observable<T | null> {
    return super.searchResource(searchMethodName, options).pipe(
      map((value: BaseResource) => value as T),
    );
  }

  public customQueryPost(path: string, requestBody?: any, options?: PagedGetOption): Observable<any> {
    return this.customQuery(HttpMethod.POST, path, requestBody, options);
  }
}
