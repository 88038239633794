<ng-container *ngIf="(modificationLayer$ | async) as theLayer">
  <ng-container *ngIf="isClickable; else notClickable;">
    <button [ngClass]="{'btn btn-sm': true, 'btn-outline-primary': isHighlighted}" (click)="onClick(theLayer)">
      {{theLayer.displayName | titlecase}} [{{theLayer.tag}}]
    </button>
  </ng-container>
  <ng-template #notClickable>
    <a>{{theLayer.displayName | titlecase}} [{{theLayer.tag}}]</a>
  </ng-template>
</ng-container>
