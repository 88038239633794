<div class="dropdown" *ngIf="translationKey$ | async as key" style="display: inline-block">
  <button class="btn btn-sm btn-outline-secondary dropdown-toggle" style="padding: 2px 10px; margin-right: 10px"
          type="button" [id]="createIdFromKeyName(key.name)" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" (click)="fetchLayers()">
    <i class="fas fa-plus"></i> Layer
  </button>

  <div class="dropdown-menu" [attr.aria-labelledby]="createIdFromKeyName(key.name)">
    <ng-container *ngIf="unusedModificationLayers$ | async as unusedLayers">
      <ng-container *ngIf="unusedLayers.length != 0; else noneLeft">
        <p class="text-center">
          <small>For language <i>{{currentLanguage.displayName}}</i></small>
        </p>
        <ng-container *ngFor="let layer of unusedLayers">
          <a class="dropdown-item">
            <app-modification-layer-visualization [modificationLayer]="layer"
                                                  (clickedEvent)="createForLayer($event)"></app-modification-layer-visualization>
          </a>
        </ng-container>
      </ng-container>
      <ng-template #noneLeft>
        <a class="dropdown-item">None available.</a>
      </ng-template>
    </ng-container>
  </div>
</div>
