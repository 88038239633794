<button type="button" class="btn btn-outline-primary " data-toggle="modal" data-target="#triggerableUrlModal">
  Trigger ...
</button>

<div class="modal fade" id="triggerableUrlModal" tabindex="-1" aria-labelledby="triggerableUrlModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable ">
    <div class="modal-content">
      <div class="modal-header">
        <b class="modal-title" id="triggerableUrlModalLabel">Trigger action</b>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-triggerable-url-manager></app-triggerable-url-manager>
      </div>
    </div>
  </div>
</div>
