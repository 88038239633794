import { Component, OnInit } from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {Language} from '../../../../services/hal/language/language';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';

@Component({
  selector: 'app-modification-layer-configuration',
  templateUrl: './modification-layer-configuration.component.html',
  styleUrls: ['./modification-layer-configuration.component.css']
})
export class ModificationLayerConfigurationComponent implements OnInit {

  layerSelected$: Subject<ModificationLayer> = new ReplaySubject<ModificationLayer>(1);

  constructor() { }

  ngOnInit() {
  }


  createNewLayer() {
    this.layerSelected$.next(new ModificationLayer());
  }

}
