<ng-container *ngIf="translationContext$ | async as context; else noLanguage">

  <div class="container-fluid text-center">
    <div class="row">
      <div class="col-2">
      </div>
      <div class="col-8">
        <pagination-controls (pageChange)="setPageNumber($event)" [maxSize]="15"
                             [responsive]="true"></pagination-controls>
      </div>
      <div class="col-2">
        <div class="dropdown">
          <a class="btn btn-outline-info dropdown-toggle" role="button" id="dropdownMenuLinkTop"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Items/Page
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkTop">
            <a class="dropdown-item" (click)="setPageSize(2)">2</a>
            <a class="dropdown-item" (click)="setPageSize(5)">5</a>
            <a class="dropdown-item" (click)="setPageSize(10)">10</a>
            <a class="dropdown-item" (click)="setPageSize(20)">20</a>
            <a class="dropdown-item" (click)="setPageSize(50)">50</a>
            <a class="dropdown-item" (click)="setPageSize(100)">100</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid text-center" style="width:95%">
    <div class="row">
      <div class="col">
        <h4>
          <app-language-visalization [language$]="currentLanguage$"></app-language-visalization>
        </h4>
      </div>
      <div class="col">
        <h4>
          <app-language-visalization [language]="context.languages.baseLanguage"></app-language-visalization>
        </h4>
      </div>
      <div class="col" *ngIf="context.languages.additionalLanguage !== null">
        <h4>
          <app-language-visalization [language$]="additionalLanguage$"></app-language-visalization>
        </h4>
      </div>
    </div>
  </div>
  <ng-template #loadingTranslations>
    <h3>Retrieving translations from backend ...</h3>
  </ng-template>

  <ng-container *ngIf="(resourceCollection$ | async) as collection; else loadingTranslations">
    <p *ngIf="!collection.resources || collection.resources.length === 0 " class="text-center"
       style="margin-top: 100px; margin-bottom: 200px">
      <i>No matching translations.</i>
    </p>

    <div class="list-group list-group-flush" *ngIf="collection.resources && collection.resources.length > 0">
      <ng-container
        *ngFor="let key of collection.resources | paginate: { itemsPerPage: pageSize, currentPage: pageNumber, totalItems: collection.totalElements };             let i = index">
        <a [ngClass]="{'list-group-item': true, 'list-group-item-modulus': i % 2 == 0}" style="padding:10px 5px;">
          <app-translation-key #translationKeyElement [translationKey]="key"
                               [translationContextLanguages$]="translationContextLanguages$"
                               (currentLanguageWasTranslatedEvent)="translationUpdatedForCurrentLanguage($event)"></app-translation-key>
        </a>
      </ng-container>
    </div>


    <div class="container-fluid text-center">
      <div class="row">
        <div class="col-2">
        </div>
        <div class="col-8">
          <pagination-controls (pageChange)="setPageNumber($event)" [maxSize]="15"
                               [responsive]="true"></pagination-controls>
        </div>
        <div class="col-2">
          <div class="dropdown">
            <a class="btn btn-outline-info dropdown-toggle" role="button" id="dropdownMenuLink"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Items/Page
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" (click)="setPageSize(2)">2</a>
              <a class="dropdown-item" (click)="setPageSize(5)">5</a>
              <a class="dropdown-item" (click)="setPageSize(10)">10</a>
              <a class="dropdown-item" (click)="setPageSize(20)">20</a>
              <a class="dropdown-item" (click)="setPageSize(50)">50</a>
              <a class="dropdown-item" (click)="setPageSize(100)">100</a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </ng-container>

</ng-container>

<ng-template #noLanguage>
  <p>
    <em class="em">...</em>
  </p>
</ng-template>


