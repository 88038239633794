import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {Observable, of, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Language} from '../../../../services/hal/language/language';

@Component({
  selector: 'app-modification-layer-visualization',
  templateUrl: './modification-layer-visualization.component.html',
  styleUrls: ['./modification-layer-visualization.component.css']
})
export class ModificationLayerVisualizationComponent implements OnInit, OnDestroy {

  @Input() modificationLayer$: Observable<ModificationLayer>;
  @Input() modificationLayer: ModificationLayer;

  @Output() clickedEvent?: EventEmitter<ModificationLayer> = new EventEmitter();
  isClickable = true;
  isHighlighted = false;

  private layerSubscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    if (this.clickedEvent.observers.length === 0) {
      this.isClickable = false;
    }

    if (this.modificationLayer$ == null) {
      if (this.modificationLayer == null) {
        throw new Error('ModificationLayers were null.');
      }
      this.modificationLayer$ = of(this.modificationLayer).pipe(shareReplay(1));
    }

    this.layerSubscription = this.modificationLayer$.subscribe(next => this.modificationLayer = next);
  }


  onClick(layer: ModificationLayer) {
    this.clickedEvent.emit(layer);
  }

  highlightIfRepresents(tag: string) {
    this.isHighlighted = this.modificationLayer.tag === tag;
  }

  ngOnDestroy(): void {
    this.layerSubscription.unsubscribe();
  }

}
