import {Component, Input, OnInit} from '@angular/core';
import {HttpMethod, TriggerableUrl} from '../../../services/hal/triggerable-url/triggerable-url';
import {ReplaySubject} from 'rxjs';
import {TriggerableUrlService} from '../../../services/hal/triggerable-url/triggerable-url.service';

@Component({
  selector: 'app-triggerable-url',
  templateUrl: './triggerable-url.component.html',
  styleUrls: ['./triggerable-url.component.css']
})
export class TriggerableUrlComponent implements OnInit {

  @Input()
  editable: boolean;

  @Input()
  triggerableUrl: TriggerableUrl;

  inEditMode: boolean;

  waitingForResolution: boolean;
  displaySuccessSign: boolean;

  triggerableUrl$: ReplaySubject<TriggerableUrl> = new ReplaySubject<TriggerableUrl>(1);

  constructor(private triggerableUrlService: TriggerableUrlService) {
  }

  ngOnInit() {
    this.triggerableUrl$.next(this.triggerableUrl);
  }

  triggerUrl(url: TriggerableUrl) {
    this.waitingForResolution = true;
    this.triggerableUrlService.triggerUrl(url).toPromise().then(
      () => this.displaySuccessSignForSeconds(3)
    ).catch(
      (ex) => {
        alert('Went straight to shit: \n' + ex.message + '\n\n\nFrom Server:\n' + ex.error.message);
      }
    ).finally(
      () => this.waitingForResolution = false
    );
  }

  toggleEditMode() {
    this.inEditMode = !this.inEditMode;
  }

  saveChanges(newName: string, newUrl: string, httpMethod: string, triggerableUrlToUpdate: TriggerableUrl) {
    console.log(httpMethod);
    this.waitingForResolution = true;
    const delta = new TriggerableUrl();
    delta.url = newUrl;
    delta.name = newName;
    delta.method = httpMethod as HttpMethod;
    delta.id = triggerableUrlToUpdate.id;

    this.triggerableUrlService.patchResource(triggerableUrlToUpdate, {body: delta}).toPromise()
      .then((updatedUrl) => {
        this.triggerableUrl$.next(updatedUrl as TriggerableUrl);
        this.displaySuccessSignForSeconds(3);
      })
      .catch((err) => alert('Uppdateringen total-sket sig: ' + err.message))
      .finally(() => {
        this.waitingForResolution = false;
        this.inEditMode = false;
      });
  }

  private displaySuccessSignForSeconds(seconds: number) {
    this.displaySuccessSign = true;
    setTimeout(() => {
      this.displaySuccessSign = false;
    }, seconds * 1000);
    return undefined;
  }

  infoButtonPressed() {
    alert('It is possible to use variables by adding predefined placeholders to the url.\nExample: https://url.com/path/action?parameter={variable_name}\nDeSWINGLISHER will automatically replace the placeholder, "{variable_name}" in the example above, with its respective predefined content.\n\n\nCurrently available placeholders/variables:\n\t{user}\t=>\tThe name of the authenticated user triggering the url.');
  }
}
