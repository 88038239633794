<h2>For developers</h2>

<hr>
<div class="text-center">
<h4>Plugins & Extensions</h4>
<br><br/>
</div>

<app-integration-plugins>
</app-integration-plugins>
