import {Component, OnDestroy, OnInit} from '@angular/core';
import {Language} from '../../../../services/hal/language/language';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {LanguageService} from '../../../../services/hal/language/language.service';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {ModificationLayerService} from '../../../../services/hal/modificationlayer/modification-layer.service';

@Component({
  selector: 'app-pour-translations',
  templateUrl: './pour-translations.component.html',
  styleUrls: ['./pour-translations.component.css']
})
export class PourTranslationsComponent implements OnInit, OnDestroy {

  language$: Observable<Language>;
  $selectedLanguageCode: ReplaySubject<string> = new ReplaySubject(1);

  layer$: Observable<ModificationLayer>;
  $selectedLayerTag: ReplaySubject<string | null> = new ReplaySubject<string | null>(1);

  private currentLanguageCode: string;
  private currentLayerTag: string;

  private selectedLanguageCodeSubscription: Subscription;
  private selectedLayerTagSubscription: Subscription;

  constructor(public activatedRoute: ActivatedRoute,
              private router: Router,
              private languageService: LanguageService,
              private layerService: ModificationLayerService) {

  }

  ngOnInit() {
    this.language$ = this.activatedRoute.queryParamMap.pipe(
      filter(queryMap => queryMap.has('language')),
      map(queryMap => queryMap.get('language')),
      switchMap(languageCode => this.languageService.getByLanguageCode(languageCode)),
      shareReplay(1)
    );
    this.layer$ = this.activatedRoute.queryParamMap.pipe(
      map(queryMap => queryMap.has('layer') ? queryMap.get('layer') : undefined),
      switchMap(layerTag => layerTag === undefined ? of(null) : this.layerService.getByTag(layerTag)),
      shareReplay(1)
    );

    this.selectedLanguageCodeSubscription = this.language$.subscribe(
      language => {
        this.currentLanguageCode = language.languageCode;
        this.$selectedLanguageCode.next(language.languageCode);
      }
    );

    this.selectedLayerTagSubscription = this.layer$.subscribe(
      layer => {
        this.currentLayerTag = layer ? layer.tag : null;
        this.$selectedLayerTag.next(this.currentLayerTag);
      }
    );
  }

  onLanguageSelected(event: Language) {
    this.currentLanguageCode = event.languageCode;
    this.navigateAccordingToCurrentParameters();
  }


  private navigateAccordingToCurrentParameters() {
    const params: Params = {};
    if (this.currentLanguageCode) {
      params.language = this.currentLanguageCode;
    }
    if (this.currentLayerTag) {
      params.layer = this.currentLayerTag;
    }
    this.router.navigate(['pour'], {queryParams: params});
  }

  ngOnDestroy(): void {
    this.selectedLanguageCodeSubscription.unsubscribe();
    this.selectedLayerTagSubscription.unsubscribe();
  }

  onLayerSelected(event: ModificationLayer) {
    this.currentLayerTag = event.tag;
    this.navigateAccordingToCurrentParameters();
  }

  clearLayer() {
    this.currentLayerTag = null;
    this.navigateAccordingToCurrentParameters();
  }
}
