import {Injectable} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {Observable} from 'rxjs';
import {ModificationLayer} from './modification-layer';
import {Language} from '../language/language';
import {TranslationKey} from '../translationkey/translation-key';
import {Translation} from '../translation/translation';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModificationLayerService extends ImprovedRestService<ModificationLayer> {

  constructor(private httpClient: HttpClient) {
    super(ModificationLayer);
  }

  public findAll(): Observable<ModificationLayer[]> {
    return super.getAll();
  }

  public createModificationLayer(modificationLayer: ModificationLayer): Observable<ModificationLayer> {
    return super.createResource({body: modificationLayer}) as Observable<ModificationLayer>;
  }

  public updateModificationLayer(modificationLayer: ModificationLayer): Observable<ModificationLayer> {
    return super.update(modificationLayer);
  }

  public findAllUnusedForTranslationKeyAndLanguage(translationKey: TranslationKey, language: Language): Observable<ModificationLayer[]> {
    return super.search('findAllUnusedForTranslationKeyAndLanguage', {
        params:
          {
            language: language.getSelfLinkHref(),
            translationKey: translationKey.getSelfLinkHref()
          }
      }
    );
  }

  public getByTag(layerTag: string): Observable<ModificationLayer | null> {
    return super.searchSingle('findByTag', {
      params:
        {tag: layerTag}
    });
  }

  public deleteModificationLayerTranslation(translation: Translation): Observable<Translation> {
    return this.httpClient.post('/modificationLayers/action/deleteTranslation?translation=' + translation.id, null) as Observable<Translation>;
  }
}
