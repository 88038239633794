import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-and-modification-layer-configuration-page',
  templateUrl: './language-and-modification-layer-configuration-page.component.html',
  styleUrls: ['./language-and-modification-layer-configuration-page.component.css']
})
export class LanguageAndModificationLayerConfigurationPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
