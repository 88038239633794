import {Injectable} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {TranslationKey} from './translation-key';
import {Release} from '../release/release';
import {Observable} from 'rxjs';
import {Language} from '../language/language';
import {HttpMethod, PagedResourceCollection, SortOrder} from '@lagoshny/ngx-hateoas-client';
import {map} from 'rxjs/operators';
import {ResourceCollection} from '@lagoshny/ngx-hateoas-client/lib/model/resource/resource-collection';

@Injectable({
  providedIn: 'root'
})
export class TranslationKeyService extends ImprovedRestService<TranslationKey> {

  constructor() {
    super(TranslationKey);
  }

  getAllForRelease(currentlyTranslatingRelease: Release): Observable<TranslationKey[]> {
    return this.search(
      'getTranslationKeysForRelease',
      {
        params: {
          release: currentlyTranslatingRelease.getSelfLinkHref()
        }
      }
    );
  }

  getAllKeysWhichAreUntranslatedForReleaseAndLanguage(release: Release, language: Language): Observable<TranslationKey[]> {
    return this.search(
      'findTranslationKeysWhichHaveUntranslatedTranslationsForReleaseAndLanguage',
      {
        params: {
          releaseId: release.id,
          languageId: language.id
        }
      }
    );
  }

  desistByNameAndRelease(translationKey: TranslationKey, release: Release): Observable<TranslationKey> {
    return this.customQueryPost('/actions/desistByNameAndRelease', null, {
      params: {
        name: translationKey.name,
        release: release.releaseName
      }
    }).pipe(map((it: TranslationKey[], num: number) => it as any));
  }


  searchBySpecification(release: Release, translated: boolean, translationLike: string, translationKeyLike: string, languageCode: string, latestTranslatorFilter: string, keyCreatorFilter: string, modificationLayerTag: string, sortOn: string, order: SortOrder, pageSize: number, pageNumber: number): Observable<PagedResourceCollection<TranslationKey>> {
    const parameterList = {};
    this.addParameterToListUnlessNull('sinceRelease', release.id, parameterList);
    this.addParameterToListUnlessNull('translations[0].translated', translated, parameterList);
    this.addParameterToListUnlessNullAsWildcard('translations[0].translation', translationLike, parameterList);
    this.addParameterToListUnlessNull('translations[0].language.languageCode', languageCode, parameterList);
    this.addParameterToListUnlessNullAsWildcard('translations[0].updatedBy', latestTranslatorFilter, parameterList);
    this.addParameterToListUnlessNullAsWildcard('createdBy', keyCreatorFilter, parameterList);
    this.addParameterToListUnlessNullAsWildcard('name', translationKeyLike, parameterList);
    this.addParameterToListUnlessNull('translations[0].modificationLayer.tag', modificationLayerTag, parameterList);

    return this.customQuery<PagedResourceCollection<TranslationKey>>(
      HttpMethod.GET,
      '/searchBySpecification',
      null,
      {
        params: parameterList,

        pageParams: {
          size: pageSize,
          page: pageNumber
        },
        sort: {
          [sortOn]: order
        }
      }
    );
  }

  private addParameterToListUnlessNull(parameterName: string, val, list: any) {
    if (val == null) {
      return;
    }

    list[parameterName] = val;
  }

  private addParameterToListUnlessNullAsWildcard(parameterName: string, val, list: any) {
    if (val == null) {
      return;
    }
    list[parameterName] = '%' + val + '%';
  }

}
