import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Language} from '../../../../services/hal/language/language';
import {Observable, of, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Component({
  selector: 'app-language-visalization',
  templateUrl: './language-visalization.component.html',
  styleUrls: ['./language-visalization.component.css']
})
export class LanguageVisalizationComponent implements OnInit, OnDestroy {

  @Input() language$: Observable<Language>;
  @Input() language: Language;
  @Output() clickedEvent?: EventEmitter<Language> = new EventEmitter();
  isClickable = true;
  isHighlighted = false;

  constructor() {
  }

  private languageSubscription: Subscription;

  ngOnInit() {
    if (this.clickedEvent.observers.length === 0) {
      this.isClickable = false;
    }

    if (this.language$ == null) {
      if (this.language == null) {
        throw new Error('Languages were null.');
      }
      this.language$ = of(this.language).pipe(shareReplay(1));
    }
    this.languageSubscription = this.language$.subscribe(next => this.language = next);
  }

  onClick(language: Language) {
    this.clickedEvent.emit(language);
  }

  highlightIfRepresents(languageCode: string) {
    this.isHighlighted = this.language.languageCode === languageCode;
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}
