<div class="container-fluid">
  <div class="row">


    <div class="col-9">
      <table class="table">
        <thead class="thead-dark">
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Username</th>
          <th scope="col">Role</th>
          <th scope="col">May Translate</th>
          <th scope="col">Real name</th>
          <th scope="col">Expires</th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr app-user *ngFor="let user of ($allUsers | async)" [user]="user"></tr>
        </tbody>
      </table>
    </div>


    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Create a new user</h5>
          <form [formGroup]="registerUserForm" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                     aria-describedby="basic-addon1" formControlName="username" >
              <div class="input-group-append" [hidden]="registerUserForm.controls.username.valid" style="color:red">
                 *
              </div>
            </div>

            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Password" aria-label="Password"
                     aria-describedby="basic-addon1" formControlName="password" >
              <div class="input-group-append" [hidden]="registerUserForm.controls.password.valid" style="color:red">
                *
              </div>
            </div>

            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Real Name" aria-label="Real Name"
                     aria-describedby="basic-addon1" formControlName="realName" >
              <div class="input-group-append" [hidden]="registerUserForm.controls.realName.valid" style="color:red">
                *
              </div>
            </div>


            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" for="roleSelection">Role</label>
              </div>
              <select class="custom-select" id="roleSelection" formControlName="role" >
                <option value="TRANSLATOR">Translator</option>
                <option value="ADMIN">Administrator (For Everything)</option>
                <option value="ACTUATOR">Actuator (For Health-monitoring)</option>
              </select>
              <div class="input-group-append" [hidden]="registerUserForm.controls.role.valid">
               *
              </div>
            </div>

            <div class="input-group mb-3" [hidden]="registerUserForm.controls.role.value !== 'TRANSLATOR'">
              <div class="input-group-prepend" >
                <label class="input-group-text" for="languageSelection" >Translates</label>
              </div>
              <select class="custom-select" id="languageSelection" formControlName="language" >
                <option *ngFor="let language of ($allLanguages | async)"
                        [value]="language.languageCode">{{language.displayName}}</option>
              </select>
              <div class="input-group-append" [hidden]="registerUserForm.controls.language.valid || registerUserForm.controls.role.value !== 'TRANSLATOR'">
                <img src="/assets/wrong.jpg" style="max-height: 42px;" alt="WRONG">
              </div>
            </div>

            <button type="submit" class="btn btn-outline-primary" [disabled]="!registerUserForm.valid">Create user</button>
          </form>
          <hr>
          <div>
            <h5>Information regarding Roles</h5>
            <ul>
            <li><b>Administrator</b> - Has no limitations in the system.</li>
            <li><b>Translator</b> - May see all languages, but only translate languages that the translator has been specifically assigned to. The GUI for the translator looks different (cleaner) than that of an Administrator as functionality is hidden and shortcuts are added.</li>
            <li><b>Actuator</b> - May monitor the health of deswinglisher using the http-endpoint "/health".</li>
            </ul>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
