import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-visual-progress',
  templateUrl: './visual-progress.component.html',
  styleUrls: ['./visual-progress.component.css']
})
export class VisualProgressComponent<T> implements OnInit, OnDestroy {

  @Input() whatIsBeingProcessed: string = 'something';

  @Input() processingMessage: string = 'Processing ...';

  @Input() processingImagePath: string = 'assets/processing.gif';

  @Input() buttonElementToDisable: HTMLButtonElement;

  @Input() successDanceTimeInMs: number = 8000;

  @Output() onSuccess: EventEmitter<T> = new EventEmitter<T>();

  @Output() onFailure: EventEmitter<Error> = new EventEmitter<Error>();

  protected processTaskSubject: Subject<Observable<T>> = new ReplaySubject<Observable<T>>();

  lastError: Error;
  protected lastProcessedEntity: T;

  currentlyProcessing: boolean;

  displayDoneNotification: boolean;

  private processingTaskSubscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.processingTaskSubscription = this.processTaskSubject.pipe(
      map(input => {
        this.setStartProcessing();
        return input;
      }),
      switchMap((v, i) => v.pipe(
        catchError((err) => {
          this.setCompletedErroneously(err);
          return of(null);
        }))
      )
    )
      .subscribe(
        next => this.setCompletedSuccessfully(next)
      );
  }

  private setProcessing(processing: boolean) {
    this.currentlyProcessing = processing;

    if (this.buttonElementToDisable != null) {
      this.buttonElementToDisable.disabled = processing;
    }
  }

  private setStartProcessing() {
    this.lastError = null;
    this.setProcessing(true);

  }

  private assertNotAlreadyProcessing() {
    if (this.currentlyProcessing) {
      throw new Error('Already processing ' + this.whatIsBeingProcessed);
    }
  }

  public processTask(task: Observable<T>) {
    this.assertNotAlreadyProcessing();
    this.processTaskSubject.next(task);
  }

  ngOnDestroy(): void {
    this.processingTaskSubscription.unsubscribe();
  }

  private setCompletedErroneously(error: Error) {
    console.log(error);
    this.lastError = error;
    this.setProcessing(false);
    this.onFailure.next(error);
  }

  private setCompletedSuccessfully(next: T) {
    if (next == null) {
      return;
    }
    this.setProcessing(false);
    this.lastProcessedEntity = next;
    this.displayDoneNotification = true;
    setTimeout(() => this.displayDoneNotification = false, this.successDanceTimeInMs);
    this.onSuccess.next(this.lastProcessedEntity);
  }

}
