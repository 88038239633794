<ng-container *ngIf="currentlyProcessing">
  <img src="{{processingImagePath}}" alt="Processing"/>
  <h3>{{processingMessage}}</h3>
</ng-container>
<ng-container *ngIf="lastError">
  <a class="text-danger">Processing of {{whatIsBeingProcessed}} was extremely unsuccessful:</a><br>
  <pre><code>{{lastError.message}}</code></pre>
  <pre><code>{{ lastError.error?.message}}</code></pre>
</ng-container>
<ng-container *ngIf="displayDoneNotification" >
  <img src="assets/success.gif" alt="Success!!!" class="rotate"/>
</ng-container>


