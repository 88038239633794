<div
  [ngClass]="{'card mb-3 text-white':true, 'bg-info':!translation.translated, 'border-dark text-dark':translation.translated, 'bg-warning':contentIsAltered, 'layerBorder':isModificationLayer()}">
  <div class="card-header" style="padding: 2px 10px;"
       *ngIf="showLanguage">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <i>
            <app-language-visalization [language$]="language$"></app-language-visalization>
          </i>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body" style="padding: 4px 20px; font-family: 'Segoe UI',sans-serif"
       (click)="setIsEditingUnlessAlreadyEditing($event)">

    <div [ngClass]="{'form-group ':true, 'd-none':!isEditing}">
      <img src="assets/explosion.gif" alt="BOOM" *ngIf="showExplosion" style="max-height: 200px"/>
      <app-html-editor *ngIf="useHtmlEditor" [currentText]="textAreaForTranslationEditing.value"
                       (htmlOutput)="onHtmlEditorContentChanged($event)"/>

      <label [attr.for]="'edit'+translation.id" class="d-none">Translation</label>
      <textarea #textAreaForTranslationEditing class="form-control" style="font-size: large"
                [attr.id]="'edit'+translation.id"
                [hidden]="useHtmlEditor"
                [rows]="5" (keydown.control.enter)="saveChanges($event)"
                (keydown.esc)="userRequestsCancelOfEdit($event)"
                (keyup)="valueChange()">{{translation.translation}}</textarea>
      <div class="container-fluid" style="margin-top: 10px; padding: 0px;">
        <div class="row">
          <div class="col text-left">
            <button type="button" class="btn btn-sm  btn-primary" (click)="saveChanges($event)" style="min-width:80px">
              Save<br>
              <small class="small" style="font-size: smaller; font-style: italic;" *ngIf="!useHtmlEditor">( CTRL + ENTER )</small>
            </button>
          </div>
          <div class="col text-center">
            <button type="button" class="btn btn-sm btn-success" (click)="userTogglesUseOfHtmlEditor()"
                    style="min-width:80px">Edit as {{ useHtmlEditor? 'Text' : 'HTML' }}<br>
              <small class="small" *ngIf="!useHtmlEditor">-</small>
            </button>
          </div>
          <div class="col text-center" *ngIf="userHasModifiedContentTemporarily">
            <button type="button" class="btn btn-sm btn-secondary" (click)="userRequestsResetOfEdit($event)"
                    style="min-width:80px">Reset<br>
              <small class="small" *ngIf="!useHtmlEditor">-</small>
            </button>
          </div>

          <div class="col text-center"
               *ngIf="!translation.translated && !userHasModifiedContentTemporarily && !!translation.id">
            <button type="button" class="btn btn-sm btn-warning" (click)="forceSetAsTranslated($event)"
                    style="min-width:80px">Force mark<br>as translated
            </button>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-sm btn-secondary" (click)="userRequestsCancelOfEdit($event)"
                    style="min-width:80px">Close
              <br>
              <small class="small" style="font-size: smaller; font-style: italic;" *ngIf="!useHtmlEditor">( ESC )</small>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isEditing">
      <b *ngIf="contentIsAltered" style="font-size: small; color: white;">Original Translation (click to see modified translation):</b>
      <blockquote class="blockquote text-left" (click)="toggleEditMode($event)" style="margin-bottom: 5px">
        <span style="font-size: large; font-family: fantasy; margin-right:3px;">&ldquo;</span>
        <a class="mb-0"
           style="font-size: large;"
           [innerText]="translation.translation.replace(newLineRegexp,'↵\n')"></a>
        <span style="font-size: large; font-family: fantasy; margin-left:3px;">&rdquo; </span>
      </blockquote>
    </ng-container>

    <div style="display: inline-block; float: left"
         *ngIf="userMayDeleteModificationLayerTranslation$ | async as mayUserDelete">
      <button *ngIf="mayUserDelete" type="button" class="btn btn-sm btn-outline-secondary"
              style="padding:0px 6px 0px 6px"
              (click)="deleteModificationLayerTranslation(translation)"><i class="fa fa-trash"
                                                                           style="font-size: small"></i></button>
    </div>
    <div style="margin: 0px; padding: 0px; display: inline-block; float:right;">

      <a *ngIf="ImprovedResource.isExistingInBackend(translation)"
         [ngClass]="{'text-info':translation.translated, 'text-dark': !translation.translated}"
         style="font-size: smaller;">
        {{translation.updatedDate === null ? 'Created' : 'Updated'}} {{translation.latestModificationDate | dateAgo}},
        by {{translation.updatedBy == null ? translation.createdBy : translation.updatedBy | titlecase}} </a>
      <span class="badge badge-primary" *ngIf="!ImprovedResource.isExistingInBackend(translation)"><abbr
        title="This translation has not yet been saved in the backend. Unless saved, it will disappear when navigating from this page.">New</abbr></span>
      <span class="badge badge-light text-dark" *ngIf="!translation.translated"><abbr
        title="This translation is inherited from the BaseLanguage Translation. It has not yet been translated.">Untranslated</abbr></span>
      <span class="badge badge-danger text-dark" *ngIf="contentIsAltered"><abbr
        title="This translation has been modified. Unless saved, the changes will disappear when navigating from this page.">Modified</abbr></span>
      <span class="badge badge-dark" *ngIf="isModificationLayer()">
        <ng-container *ngIf="modificationLayer$ | async as layer">
        <abbr
          title="This Translation belongs to a ModificationLayer. It will be displayed instead of the BaseTranslation for customers having the ModificationLayer '{{layer.displayName}}' (tag '{{layer.tag}}') and the language code '{{ (language$ | async).languageCode}}' setup in their frontend-configuration.">
          <app-modification-layer-visualization
            [modificationLayer]="layer"></app-modification-layer-visualization>
        </abbr>
          </ng-container>
      </span>
    </div>
  </div>
</div>
