import {ImprovedResource} from '../../improved-resource';
import {Release} from '../release/release';
import {Language} from '../language/language';
import {Observable} from 'rxjs';
import {TranslationKey} from '../translationkey/translation-key';
import {ModificationLayer} from '../modificationlayer/modification-layer';
import {catch404ThenReturnNull} from '../../../utility/operator-functions-to-visualize-content-in-pipe';
import {HateoasResource} from '@lagoshny/ngx-hateoas-client';

@HateoasResource('translations')
export class Translation extends ImprovedResource {
  translation: string;
  translated: boolean;
  translationKeyId: number;
  translationKey: TranslationKey;
  desistedInRelease: Release;
  sinceRelease: Release;
  language: Language;
  modificationLayer?: ModificationLayer;
  forModificationLayer: boolean;

  // For some reason Translation  gets instantiated as a Resource (its parents parent) and not a Translation. So instance methods does not work.
  public static getSinceRelease(forTranslation: Translation): Observable<Release> {
    return forTranslation.getRelation<Release>('sinceRelease');
  }

  public static getLanguage(forTranslation: Translation): Observable<Language> {
    return forTranslation.getRelation<Language>('language');
  }

  public static getTranslationKey(forTranslation: Translation): Observable<TranslationKey> {
    return forTranslation.getRelation<TranslationKey>('translationKey');
  }

  public static getModificationLayer(forTranslation: Translation): Observable<ModificationLayer | null> {
    return forTranslation.getRelation<ModificationLayer>('modificationLayer').pipe(catch404ThenReturnNull()); // TODO: Handle 404, or is that implemented by the framework and converted to null?
  }
}
