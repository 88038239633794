import {Component, OnInit} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {TriggerableUrl} from '../../../services/hal/triggerable-url/triggerable-url';
import {TriggerableUrlService} from '../../../services/hal/triggerable-url/triggerable-url.service';
import {delay, retryWhen, take} from 'rxjs/operators';

@Component({
  selector: 'app-triggerable-url-manager',
  templateUrl: './triggerable-url-manager.component.html',
  styleUrls: ['./triggerable-url-manager.component.css']
})
export class TriggerableUrlManagerComponent implements OnInit {

  triggerableUrls$: ReplaySubject<TriggerableUrl[]> = new ReplaySubject<TriggerableUrl[]>(1);

  constructor(private triggerableUrlService: TriggerableUrlService) {
    this.triggerableUrlService.getAll().pipe(
      retryWhen(errors => errors.pipe(delay(5000), take(1000)))
    ).toPromise()
      .then((triggerableUrls) => this.triggerableUrls$.next(triggerableUrls))
      .catch((ex) => alert('Unable to load triggerable urls. Please refresh page. ' + ex.message));
  }

  ngOnInit() {

  }

}
