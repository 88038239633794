import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {ModificationLayerVisualizationComponent} from '../modification-layer-visualization/modification-layer-visualization.component';
import {ModificationLayerService} from '../../../../services/hal/modificationlayer/modification-layer.service';

@Component({
  selector: 'app-modification-layer-list',
  templateUrl: './modification-layer-list.component.html',
  styleUrls: ['./modification-layer-list.component.css']
})
export class ModificationLayerListComponent implements OnInit, OnDestroy, AfterViewInit {
  layers$: Observable<ModificationLayer[]>;
  @Output() layerSelectedEvent: EventEmitter<ModificationLayer> = new EventEmitter();
  @Input() text = 'Select Layer: ';
  @Input() $selectedLayerTag?: Observable<string>;
  @ViewChildren('layerComponent') layerComponents: ModificationLayerVisualizationComponent[];

  private highlightlingSubscription: Subscription;
  private currentlySelectedTagSubscription: Subscription;

  private layerChangedSubject: Subject<ModificationLayer> = new ReplaySubject(1);

  private refreshContent$: ReplaySubject<void> = new ReplaySubject<void>();

  private layerChangedSubscription: Subscription;

  constructor(private modificationLayerService: ModificationLayerService) {
  }

  ngOnInit() {
    this.layers$ = this.refreshContent$.pipe(
      switchMap(() => this.modificationLayerService.getAll()),
      shareReplay(1)
    );

    if (!this.$selectedLayerTag) {
      this.$selectedLayerTag = this.layerChangedSubject.pipe(map(layer => layer.tag), shareReplay(1));
    }

    this.layerChangedSubscription = this.layerChangedSubject.subscribe(newLayer => this.layerSelectedEvent.emit(newLayer));

    this.highlightlingSubscription = this.layers$.pipe(
      switchMap(() => this.$selectedLayerTag),
    ).subscribe(countryCode => this.setHighlightedLayerByTag(countryCode));

    this.refreshContent();
  }


  public refreshContent() {
    this.refreshContent$.next();
  }

  onLayerSelected($event: ModificationLayer) {
    this.layerChangedSubject.next($event);
  }

  private setHighlightedLayerByTag(selectedLayerTag: string) {
    setTimeout(() =>
      this.layerComponents.forEach(
        component => component.highlightIfRepresents(selectedLayerTag)
      )
    );
  }

  ngAfterViewInit(): void {
    this.currentlySelectedTagSubscription = this.$selectedLayerTag.subscribe(
      next => this.setHighlightedLayerByTag(next)
    );
  }

  ngOnDestroy(): void {
    this.currentlySelectedTagSubscription.unsubscribe();
    this.highlightlingSubscription.unsubscribe();
    this.layerChangedSubscription.unsubscribe();
  }


}
