<h3>Languages</h3>
<div class="card" style="margin-top: 50px">
  <div class="card-body">
    <div class="container-fluent">
      <div class="row">
        <div class="col-10">
          <app-language-list [text]="'Edit Language: '"
                             (languageSelectedEvent)="languageSelected$.next($event)"></app-language-list>
        </div>
        <div class="col-2">
          <button type="button" class="btn btn-primary" (click)="createNewLanguage()">+ New Language</button>
        </div>
      </div>
    </div>


  </div>

  <div *ngIf="languageSelected$ | async as language" class="card" style="margin: 20px">
    <div class="card-body">
      <h3><small class="text-muted" style="margin-right:15px">Language</small>
        <app-language-visalization [language$]="languageSelected$"></app-language-visalization>
      </h3>

      <div class="text-left">
        <app-language-editor [editedLanguage$]="languageSelected$"></app-language-editor>
      </div>
    </div>
  </div>
</div>


