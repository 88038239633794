import {Injectable, Injector} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {Language} from './language';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends ImprovedRestService<Language> {

  constructor() {
    super(Language);
  }

  public getByLanguageCode(languageCode: string): Observable<Language> {
    return this.searchSingle('findLanguageByLanguageCode',
      {
        params: {
          languageCode: languageCode
        }
      }
    );
  }

  public createLanguage(language: Language): Observable<Language> {
    return super.createResource({body: language}) as Observable<Language>;
  }

  public updateLanguage(language: Language): Observable<Language> {
    return super.update(language) as Observable<Language>;
  }
}
