import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {VisualProgressComponent} from '../../../controls/visual-progress/visual-progress.component';
import {ModificationLayerService} from '../../../../services/hal/modificationlayer/modification-layer.service';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';

@Component({
  selector: 'app-modification-layer-editor',
  templateUrl: './modification-layer-editor.component.html',
  styleUrls: ['./modification-layer-editor.component.css']
})
export class ModificationLayerEditorComponent implements OnInit, OnDestroy {
  @Input() editedLayer$?: Observable<ModificationLayer> = new BehaviorSubject(new ModificationLayer()).pipe(shareReplay(1));
  @Output() modificationLayersChanged: EventEmitter<ModificationLayer> = new EventEmitter<ModificationLayer>();

  @ViewChild('visualProcessor', { static: true }) visualProcessorComponent: VisualProgressComponent<ModificationLayer>;

  currentlyEditedLayer: ModificationLayer;

  editedLayerSubscription: Subscription;

  constructor(private modificationLayerService: ModificationLayerService) {
  }

  isEdit(): boolean {
    return this.currentlyEditedLayer.id !== undefined;
  }

  ngOnInit() {
    this.editedLayerSubscription = this.editedLayer$.subscribe(newLayer => this.currentlyEditedLayer = newLayer);
  }

  ngOnDestroy(): void {
    this.editedLayerSubscription.unsubscribe();
  }

  saveLayer() {
    if (this.isEdit()) {
      this.assertUserIsSureAndUpdate();
    } else {
      this.assertUserIsSureAndCreate();
    }
  }

  private assertUserIsSureAndUpdate() {
    if (confirm('Update to ' + this.currentlyEditedLayer.displayName + ' [' + this.currentlyEditedLayer.tag + ']? Seriously?')) {
      alert('Weird. Suit yourself.');
      this.visualProcessorComponent.processTask(this.modificationLayerService.updateModificationLayer(this.currentlyEditedLayer));
    } else {
      alert('Cluuuuck cluck cluck!!!');
    }
  }

  private assertUserIsSureAndCreate() {
    if (confirm('Create ModificationLayer ' + this.currentlyEditedLayer.displayName + ' [' + this.currentlyEditedLayer.tag + ']?')) {
      this.visualProcessorComponent.processTask(this.modificationLayerService.createModificationLayer(this.currentlyEditedLayer));
    } else {
      alert('Cluuuuck cluck cluck!!!');
    }
  }

}
