import {Component, OnInit} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {Language} from '../../../../services/hal/language/language';

@Component({
  selector: 'app-language-configuration',
  templateUrl: './language-configuration.component.html',
  styleUrls: ['./language-configuration.component.css']
})
export class LanguageConfigurationComponent implements OnInit {

  languageSelected$: Subject<Language> = new ReplaySubject<Language>(1);

  constructor() {
  }

  ngOnInit() {
  }

  createNewLanguage() {
    this.languageSelected$.next(new Language());
  }
}
