import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {OverviewComponent} from './components/page/overview/overview.component';
import {TranslateComponent} from './components/page/translate/translate.component';
import {PourTranslationsComponent} from './components/page/pour/pour-translations/pour-translations.component';
import {UserPageComponent} from './components/page/user/user-page/user-page.component';
import {DevelopmentPageComponent} from './components/page/development-page/development-page.component';
import {LanguageAndModificationLayerConfigurationPageComponent} from './components/page/language-and-modification-layer-configuration-page/language-and-modification-layer-configuration-page.component';
import {ModificationLayerConfigurationComponent} from './components/page/language-and-modification-layer-configuration-page/modification-layer-configuration/modification-layer-configuration.component';
import {LanguageConfigurationComponent} from './components/page/language-and-modification-layer-configuration-page/language-configuration/language-configuration.component';
import {KeyTranslationWidgetComponent} from './components/page/key-translation-widget/key-translation-widget.component';


const routes: Routes = [
  {
    path: '', component: OverviewComponent
  },
  {
    path: 'overview', component: OverviewComponent
  },
  {
    path: 'translate', component: TranslateComponent
  },
  {
    path: 'pour', component: PourTranslationsComponent
  },
  {
    path: 'user', component: UserPageComponent
  },
  {
    path: 'development', component: DevelopmentPageComponent
  },
  {
    path: 'modificationLayers', component: ModificationLayerConfigurationComponent
  },
  {
    path: 'languages', component: LanguageConfigurationComponent
  },
  {
    path: 'languagesAndModificationLayers', component: LanguageAndModificationLayerConfigurationPageComponent
  },
  {
    path: 'keyTranslationWidget', component: KeyTranslationWidgetComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})]
})
export class AppRoutingModule {
}

