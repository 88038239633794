<ng-container *ngIf="language$ | async as language">
  <label for="theSink">Feed me with translations | <b>{{language.displayName}}</b>
    <ng-container *ngIf="currentModificationLayer as layer">
      with layer <b><app-modification-layer-visualization [modificationLayer]="layer"></app-modification-layer-visualization></b>
    </ng-container>
  </label>
  <textarea #theSink class="form-control"
            id="theSink" style="min-height:500px; width: 90%" *ngIf="!displayDoneNotification">{{DEFAULT_TEXT_AREA_VALUE}}</textarea>
  <ng-container *ngIf="displayDoneNotification">
    <div class="slap" style="width: 1000px" >
      <i style="color: red;">DONE!!1</i>
    </div>

  </ng-container>
  <button [disabled]="activity" type="button" class="btn btn-primary btn-lg" (click)="pourTranslations(language)" >
    <span class="spinner-border spinner-border-lg" role="status"
          aria-hidden="true"></span>
    Pour translations
  </button>
  <button type="button" class="btn btn-secondary btn-lg" (click)="userRequestsClearing()">Clear</button>

</ng-container>
