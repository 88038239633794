<ng-container *ngIf="triggerableUrl$ | async as url">
  <ng-container *ngIf="!waitingForResolution; else waitForResolution;">
    <ng-container *ngIf="!inEditMode; else editMode;">
      <a *ngIf="editable" [innerText]="'✍'" style="font-size: large; margin-right: 30px; margin-left: 10px;"
         (click)="toggleEditMode()"></a>
      <a (click)="triggerUrl(url)" style="color:blue; cursor: pointer">{{url.name}}</a>
    </ng-container>
    <ng-template #editMode>
      <div class="container-fluid">
        <hr>
        <p><b>Editing </b><i>{{url.name}}</i></p>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Name</span>
          <input type="text" class="form-control" placeholder="Name" aria-label="Name" aria-describedby="basic-addon1"
                 value="{{url.name}}" #nameInput>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon2">Url</span>
          <input type="text" class="form-control" placeholder="Url" aria-label="Url" aria-describedby="basic-addon2"
                 value="{{url.url}}" #urlInput>
          <button class="btn btn-info" type="button" (click)="infoButtonPressed()">?</button>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Http Method</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01" [value]="url.method" #httpMethodInput>
            <ng-container *ngFor="let httpMethod of ['GET','POST','PUT','PATCH','HEAD', 'DELETE', 'OPTIONS', 'TRACE']">
              <option [value]="httpMethod" [selected]="url.method === httpMethod">{{httpMethod}}</option>
            </ng-container>
          </select>
        </div>
        <button class="btn btn-outline-primary" type="button"
                (click)="saveChanges(nameInput.value, urlInput.value, httpMethodInput.value, url)">Save
        </button>
        <button class="btn btn-outline-secondary" type="button"
                (click)="toggleEditMode()">Cancel
        </button>
        <hr>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #waitForResolution>
    <a>Please wait ...</a>
  </ng-template>
</ng-container>
<ng-container *ngIf="displaySuccessSign">
  <a [innerText]="'✔'" style="color: green; padding-right: 10px; padding-left: 10px; font-size: x-large"></a>
</ng-container>
