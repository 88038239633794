import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';


@Component({
  selector: 'app-input-debounce',
  templateUrl: './input-debounce.component.html',
  styleUrls: ['./input-debounce.component.css']
})
export class InputDebounceComponent implements OnInit, OnDestroy {

  @Input() placeholder: string;
  @Input() delay = 300;
  @Input() initialValue: string;
  @Input() highlightTrailingWhitespace = true;
  @Output() value: EventEmitter<string> = new EventEmitter<string>();

  @Output() hasValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  public inputValue: string;

  whitespaceDetected: boolean;

  private inputSubscription: Subscription;
  private hasValueSubscription: Subscription;

  constructor(private elementRef: ElementRef) {
    this.hasValue.emit(false);
  }

  ngOnInit(): void {
    if (this.initialValue !== undefined && this.initialValue !== null) {
      this.set(this.initialValue);
    }
    const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(
        map(() => this.inputValue),
        debounceTime(this.delay),
        distinctUntilChanged()
      );

    this.inputSubscription = eventStream.subscribe(input => this.value.emit(input));
    this.hasValueSubscription = eventStream.subscribe(input => this.emitHasValueBasedOnInput(input));
  }

  private emitHasValueBasedOnInput(input: string) {
    const emitValue = input !== null && input !== undefined && input !== '';
    this.whitespaceDetected = this.isStartsOrEndsWithWhitespace(input);
    this.hasValue.emit(emitValue);
  }

  private isStartsOrEndsWithWhitespace(input: string) {
    return input !== null && input !== undefined && (input.startsWith(' ') || input.endsWith(' '));
  }

  ngOnDestroy(): void {
    this.inputSubscription.unsubscribe();
    this.hasValueSubscription.unsubscribe();
  }

  public clear() {
    this.set('');
  }

  public set(value: string) {
    if (this.inputValue !== value) {
      this.inputValue = value;
      this.emitHasValueBasedOnInput(value);
    }
  }
}
