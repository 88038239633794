import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {UserPermissions} from './user-permissions';
import {Observable} from 'rxjs';
import {delay, map, retryWhen, share, shareReplay, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient) {
  }

  getCurrentUsersPermissions(): Observable<UserPermissions> {
    return this.getUserPermissionsObservable();
  }

  private getUserPermissionsObservable(): Observable<UserPermissions> {
    return this.http.get('/user/self/authorities').pipe(
      map((permissions: string[]) => this.createUserPermissionObject(permissions)),
      retryWhen(errors => errors.pipe(delay(5000), take(1000))),
      shareReplay(1));
  }

  private createUserPermissionObject(permissions: string[]) {

    const userPermissions = new UserPermissions(permissions);
    return userPermissions;
  }

  getPermissionsFor(id: number): Observable<UserPermissions> {
    return this.http.get('/user/' + id + '/authorities').pipe(
      map((permissions: string[]) => new UserPermissions(permissions)),
      share()
    );
  }
}
