<br>
<br>
<div class="container text-center">
  <div class="row ">
    <div class="col">
      <h1>
        Welcome to <img src="assets/de-swinglisher-trimmed.png" alt="DeSWINGLISHER" style="max-height: 56px;"/>
      </h1>
    </div>
  </div>
  <div class="row" style="margin-top: 30px;">
    <div class="col">
      <ng-container *ngIf="$user | async as user; else pleaseLogin;">
        <p><i style="color:#606060; font-size: larger">Nice to see you <em class="em"
                                                                           style="color:black;">{{user.realName}}</em>.
          What
          would you like to do?</i></p>
        <ng-container *ngIf="user.authorities.hasRole('ADMIN')">

          <div style="text-align: center; margin-top:40px;">
            <p><a routerLink="/translate" [queryParams]="{'translationKey': 'productnews_'}" routerLinkActive="active" style="font-size: x-large;">❯ Product News</a> | <a routerLink="/translate" [queryParams]="{'translationKey': 'productnews_', 'language':'sv'}" routerLinkActive="active" style="font-size: x-large;"> SV</a> </p>
            <p><a routerLink="/translate" routerLinkActive="active" style="font-size: x-large;">❯ Translate</a></p>
            <p><a routerLink="/user" routerLinkActive="active" style="font-size: x-large;">❯ Manage Users and
            Accounts</a></p>
            <p><a routerLink="/pour" routerLinkActive="active" style="font-size: x-large;">❯ Pour Translations</a></p>
            <p><a routerLink="/development" routerLinkActive="active" style="font-size: x-large;">❯ Developer Page (Plugins & Extensions)</a></p>
            <ng-container *ngIf="$latestRelease | async as latestRelease">
              <p><a (click)="onDownloadTranslations()"
                    [href]="'/translate/toAllLanguages?release=' + latestRelease.releaseName + '&contentFormat=JSON&useCompression=true'"
                    style="font-size: x-large;"
                    [download]="'Translations_' + latestRelease.releaseName + '_' + (date.now() | date: 'YYYY-MM-dd_HH_mm_ss'  ) + '.zip'">❯
                Download all translations
                for {{latestRelease.releaseName}}</a></p>
              <b *ngIf="downloading">Downloading... Please be patient. The file is generated on demand and this might
                take a couple of minutes.</b>
            </ng-container>
          </div>


        </ng-container>

        <ng-container *ngIf="user.authorities.hasRole('TRANSLATOR')">
          <a *ngFor="let code of user.authorities.getLanguageCodes()" routerLinkActive="active" routerLink="/translate"
             [queryParams]="{language: code}"
             style="font-size: x-large; color: transparent;  text-shadow: 0 0 0 navy;"
             [innerText]="'✒ Translate for language \'' + code.toLowerCase() +'\''">
          </a>
        </ng-container>
      </ng-container>
      <ng-template #pleaseLogin>
        <i>Please login to DeSWINGLISHER.<br>(You may need to refresh this page if you haven't been asked for your
          credentials by your browser).</i>
      </ng-template>

    </div>
  </div>
</div>


