<p>
  <ng-container *ngIf="isEdit()">
    <a>Id </a><b>{{currentlyEditedLanguage.id}}</b>
  </ng-container>
  <ng-container *ngIf="!isEdit()">
    <a>Creating </a> <b>New Language</b>
  </ng-container>
</p>
<div>
  <div class="mb-3">
    <label for="formControlInput1" class="form-label">Display Name</label>
    <input type="text" class="form-control" id="formControlInput1" [(ngModel)]="currentlyEditedLanguage.displayName"/>
  </div>
  <div class="mb-3">
    <label for="formControlInput2" class="form-label">Language Code</label>
    <input type="text" class="form-control" id="formControlInput2" [(ngModel)]="currentlyEditedLanguage.languageCode">
  </div>
  <button type="button" class="btn btn-primary" (click)="saveLanguage()"
          #saveButton>{{isEdit() ? 'Update' : 'Create'}}</button>
  <br>
  <app-visual-progress [buttonElementToDisable]="saveButton" #visualProcessor></app-visual-progress>
</div>
