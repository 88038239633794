<div class="container-fluid">
  <div class="row text-left">
    <div class="col-3 text-left">
      <a class="" [innerHTML]="text"></a>
    </div>
    <div class="col text-left">
      <ng-container *ngFor="let layer of (layers$ | async)" class="text-left" >
        <app-modification-layer-visualization #layerComponent [modificationLayer]="layer"
                                              (clickedEvent)="onLayerSelected($event)" style="margin-left: 5px"></app-modification-layer-visualization>
      </ng-container>
    </div>
  </div>
</div>

