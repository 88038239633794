import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, of, race, Subject} from 'rxjs';
import {catchError, defaultIfEmpty, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {Language} from '../../../services/hal/language/language';
import {TranslationContextLanguages} from '../translate/translation-context-languages';
import {LanguageService} from '../../../services/hal/language/language.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {BaseLanguageService} from '../../../services/domain/base-language.service';
import {TranslationKey} from '../../../services/hal/translationkey/translation-key';
import {TranslationKeyService} from '../../../services/hal/translationkey/translation-key.service';
import {ReleaseService} from '../../../services/hal/release/release.service';
import {Translation} from '../../../services/hal/translation/translation';
import {ModificationLayerService} from '../../../services/hal/modificationlayer/modification-layer.service';
import {ModificationLayer} from '../../../services/hal/modificationlayer/modification-layer';

@Component({
  selector: 'app-key-translation-widget',
  templateUrl: './key-translation-widget.component.html',
  styleUrls: ['./key-translation-widget.component.css']
})
export class KeyTranslationWidgetComponent implements OnInit {

  translationContextLanguages$: Observable<TranslationContextLanguages>;
  $initializedWithQueryParameters: Observable<ParamMap>;

  $triggerCreationOfNewContext: Subject<any> = new Subject();

  $translationKey: Observable<TranslationKey>;


  constructor(
    private languageService: LanguageService,
    private baseLanguageService: BaseLanguageService,
    private translationKeyService: TranslationKeyService,
    private releaseService: ReleaseService,
    private modificationLayerService: ModificationLayerService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.$initializedWithQueryParameters = this.activatedRoute.queryParamMap.pipe(shareReplay(1));

    this.translationContextLanguages$ = race(this.$triggerCreationOfNewContext).pipe(
      switchMap(() =>
        combineLatest([
            this.mapQueryParameterToLanguage('language', true),
            this.mapQueryParameterToLanguage('additionalLanguage', false)
          ]
        )
      ),
      switchMap((currentAndAdditionalLanguage) => this.createTranslationContextLanguages(currentAndAdditionalLanguage[0], currentAndAdditionalLanguage[1])),
      shareReplay(1)
    );

    this.$translationKey = combineLatest([
      this.getQueryParameter('translationKey'),
      this.releaseService.getLatestRelease()
    ]).pipe(
      switchMap(translationKeyNameAndRelease => this.translationKeyService.searchSingle('findTranslationKeyByNameInRelease', {
        params: {
          name: translationKeyNameAndRelease[0],
          release: translationKeyNameAndRelease[1]
        }
      }))
    );

    setTimeout(() => this.$triggerCreationOfNewContext.next(true), 0);
  }

  private getQueryParameter(name: string) {
    return this.$initializedWithQueryParameters
      .pipe(
        filter(parameterMap => parameterMap.has(name)),
        map(parameterMap => parameterMap.get(name)),
        shareReplay(1)
      );
  }

  mapQueryParameterToLanguage(parameterName: string, required: boolean): Observable<Language> {
    return this.$initializedWithQueryParameters
      .pipe(
        filter(queryMap => required ? queryMap.has(parameterName) : true),
        map(queryMap => queryMap.get(parameterName)),
        defaultIfEmpty(null),
        switchMap(languageCode => (languageCode == null || languageCode === '' ? of(null) : this.languageService.getByLanguageCode(languageCode))),
        shareReplay(1)
      );
  }

  private async createTranslationContextLanguages(currentLanguage: Language, additionalLanguage: Language) {
    const baseLanguage = await this.baseLanguageService.getBaseLanguage();
    return new TranslationContextLanguages(currentLanguage, baseLanguage, additionalLanguage);
  }

  async newTranslationAvailable(translation: Translation) {
    const keyName = await translation.getRelation<TranslationKey>('translationKey').pipe(map(translationKey => translationKey.name)).toPromise();
    const translationLanguageCode = await translation.getRelation<Language>('language').pipe(map(language => language.languageCode)).toPromise();
    const translationModificationLayerTag = await translation.getRelation<ModificationLayer>('modificationLayer').pipe(map(modificationLayer => modificationLayer.tag), catchError(() => of(null))).toPromise();

    const translationUpdatedSummary = {
      translationKeyName: keyName,
      translation: translation.translation,
      languageCode: translationLanguageCode,
      modificationLayerTag: translationModificationLayerTag
    };
    alert(JSON.stringify(translationUpdatedSummary));

    window.postMessage(
      {
        translationUpdated: translationUpdatedSummary
      },
    );
  }

}
