import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import {combineLatest, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {Language} from '../../../../services/hal/language/language';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {map, share, switchMap} from 'rxjs/operators';
import {Translation} from '../../../../services/hal/translation/translation';
import {TranslationService} from '../../../../services/hal/translation/translation.service';
import {TranslationComponent} from '../../translation/translation/translation.component';
import {ModificationCreationRequest} from '../add-modification-to-layer/modification-creation-request';
import {TranslationCreationContext} from './translation-creation-context';
import {ReleaseService} from '../../../../services/hal/release/release.service';
import {Release} from '../../../../services/hal/release/release';
import {TranslationEventDto} from '../translation-key/translation-event-dto';

@Component({
  selector: 'app-translations-for-key-and-language',
  templateUrl: './translations-for-key-and-language.component.html',
  styleUrls: ['./translations-for-key-and-language.component.css']
})
export class TranslationsForKeyAndLanguageComponent implements OnInit, OnDestroy {

  @Input() language$: Observable<Language>;
  @Input() translationKey$: Observable<TranslationKey>;
  @Input() triggerRefreshOfTranslationsFromBackend$?: Subject<void> = new ReplaySubject();
  @Input() showLanguage = false;

  @Output() translationWasUpdatedByUserEvent: EventEmitter<TranslationEventDto> = new EventEmitter();

  translations$: Observable<Translation[]>;

  baseTranslation: Translation;
  baseTranslationUpdatedSubscription: Subscription;

  translationCreationContext: TranslationCreationContext;

  @ViewChildren('translationComponents') translationComponents: TranslationComponent[];

  constructor(private translationService: TranslationService, private releaseService: ReleaseService) {

  }

  ngOnInit() {
    this.translations$ = this.triggerRefreshOfTranslationsFromBackend$.pipe(
      switchMap(() => combineLatest(this.translationKey$, this.language$)),
      switchMap((keyAndLanguage) => this.translationService.getForKeyAndLanguage(keyAndLanguage[0], keyAndLanguage[1])),
      map(translations => this.sortArrayPlacingTranslationsWithModificationLayersLowest(translations)),
      share()
    );

    this.baseTranslationUpdatedSubscription = this.translations$.pipe(
      map(translations => translations[0]),
    ).subscribe(newBaseTranslation => {
      if (newBaseTranslation.forModificationLayer) {
        alert('Found translation_key which had no base translation for a language.');
        throw Error('Found translation_key which had no base translation for a language.');
      }
      this.baseTranslation = newBaseTranslation;
    });

    this.triggerRefreshOfTranslationsFromBackend$.next();
  }

  private sortArrayPlacingTranslationsWithModificationLayersLowest(translations: Translation[]): Translation[] {
    return translations.sort((t1, t2) => t1.forModificationLayer === t2.forModificationLayer ? 0 : (t1.forModificationLayer ? 1 : -1));
  }

  translationWasUpdatedByUser($event: TranslationEventDto) {
    this.triggerRefreshOfTranslationsFromBackend$.next();
    this.translationWasUpdatedByUserEvent.emit($event);
  }

  public isTranslated(): boolean {
    return this.getBaseTranslationComponent().isTranslated();
  }

  private getBaseTranslationComponent(): TranslationComponent {
    const translationComponentsRepresentingBaseTranslation = this.translationComponents.filter(comp => comp.isRepresentingBaseTranslation());
    if (translationComponentsRepresentingBaseTranslation.length !== 1) {
      throw new Error('Could not find base translation for a TranslationKey.');
    }
    return translationComponentsRepresentingBaseTranslation[0];
  }

  ngOnDestroy(): void {
    this.baseTranslationUpdatedSubscription.unsubscribe();
  }

  setEditMode(editMode: boolean) {
    this.getBaseTranslationComponent().setEditMode(editMode);
  }

  public async newModificationCreationRequest(modificationCreationRequest: ModificationCreationRequest) {
    const baseTranslation: Translation = this.getBaseTranslationComponent().translation;
    const sinceRelease: Release = await Translation.getSinceRelease(baseTranslation).toPromise();

    this.translationCreationContext = new TranslationCreationContext(
      baseTranslation.translation,
      sinceRelease,
      modificationCreationRequest.language,
      modificationCreationRequest.key,
      modificationCreationRequest.layer
    );
  }

  additionalTranslationCreatedByUser($event: Translation) {
    this.translationCreationContext = null;
    this.triggerRefreshOfTranslationsFromBackend$.next();
  }

  translationWasDeletedByUser($event: Translation) {
    this.triggerRefreshOfTranslationsFromBackend$.next();
  }
}
