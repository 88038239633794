import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../services/domain/user/user.service';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../services/domain/user/user';
import {VersionInformation} from '../../services/operations/version-information';
import {VersionService} from '../../services/operations/version.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

  $user: Observable<User>;
  $version: Observable<VersionInformation>;

  hideMenuSubscription: Subscription;

  hideMenu: boolean;

  constructor(private userService: UserService, private versionService: VersionService, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.$user = this.userService.getUser();
    this.$version = this.versionService.getVersion();
    this.hideMenuSubscription = this.activatedRoute.queryParamMap.pipe(map(params => this.resolveHideMenu(params))).subscribe(res => {
      this.hideMenu = res;
    });
  }

  private resolveHideMenu(paramMap: ParamMap): boolean {
    if (!paramMap.has('hideMenu')) {
      return false;
    }
    return paramMap.get('hideMenu') === 'true';
  }

  ngOnDestroy(): void {
    this.hideMenuSubscription.unsubscribe();
  }

}
