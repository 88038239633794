<div *ngIf="hideThatEnormousFilterBar" class="text-center" style="margin-bottom: 2px"
     xmlns="http://www.w3.org/1999/html">
  <button type="button" class="btn btn-sm btn-outline-info fancypants-border " (click)="setExpandFilterBar(true)"
          style="padding: 6px 20px">Open
    the beautiful, well-designed, humongous options-window again.
  </button>
</div>
<div [ngClass]="{'container-fluid':true,'fancypants-border':true}" *ngIf="!hideThatEnormousFilterBar">
  <ng-container>
    <div class="row">
      <div class="col" *ngIf="selectedLanguageCode != null" [@fadeInOut]>
        <div class="row"><h6><u>Filter & Search</u></h6></div>
        <div class="row">
          <div class="col-5 text-left">
            <div class="btn-group-toggle">
              <label>
                Show</label>
              <div class="btn-group btn-group-sm btn-sm btn-group-toggle"
                   (click)="onTranslationStatusFilterChange($event)" data-toggle="buttons">
                <label [ngClass]="{'btn': true, 'btn-info': true, 'active': translationStatusFilter === 'ALL'}">
                  <input type="radio" name="options" id="ALL" value="ALL"> Everything
                </label>
                <label
                  [ngClass]="{'btn': true, 'btn-info': true, 'active': translationStatusFilter === 'UNTRANSLATED'}">
                  <input type="radio" name="options" id="UNTRANSLATED" value="UNTRANSLATED"> Untranslated
                </label>
                <label
                  [ngClass]="{'btn': true, 'btn-info': true, 'active': translationStatusFilter === 'TRANSLATED'}">
                  <input type="radio" name="options" id="TRANSLATED" value="TRANSLATED"> Translated
                </label>
              </div>

            </div>
          </div>
        </div>
        <div class="row bg-light">
          <div class="col">
            <div class="row ">
              <div class="col-6 text-left" style="padding: 4px;">
                <app-input-debounce #translationTextFilterComponent [delay]="300"
                                    placeholder="Translation contains text ..."
                                    (value)="newTranslationSearchFilter($event)"
                                    [initialValue]="currentTranslationTextFilter"
                                    (hasValue)="translationContainsFilterHasValue$.next($event)"></app-input-debounce>
              </div>
              <div class="col-5 text-left" style="padding: 4px;">
                <app-input-debounce #translationKeyNameFilterComponent [delay]="300" placeholder="translation_key_name"
                                    (value)="newTranslationKeySearchFilter($event)"
                                    [initialValue]="currentTranslationKeyNameFilter"
                                    (hasValue)="keyNameFilterHasValue$.next($event)"></app-input-debounce>
              </div>
              <div class="col-1" style="padding: 4px;">
                <button type="button"
                        [ngClass]="{'btn': true,  'btn-outline-secondary': clearButtonShouldBeDisabled, 'btn-outline-primary': !clearButtonShouldBeDisabled}"
                        (click)="clearSearchFilters()" [disabled]="clearButtonShouldBeDisabled">Clear
                </button>
              </div>

            </div>
            <div class="row">
              <div class="col-6 text-left" style="padding: 4px;">
                <app-input-debounce #translatorNameFilterComponent [delay]="300" placeholder="Latest translator ..."
                                    (value)="newTranslatorSearchFilter($event)"
                                    [initialValue]="currentTranslatorTextFilter"
                                    (hasValue)="latestTranslatorFilterHasValue$.next($event)"></app-input-debounce>
              </div>
              <div class="col-5 text-left" style="padding: 4px;">
                <app-input-debounce #KeyCreatorNameFilterComponent [delay]="300" placeholder="Key created by ..."
                                    (value)="newKeyCreatorKeySearchFilter($event)"
                                    [initialValue]="currentKeyCreatorNameFilter"
                                    (hasValue)="keyCreatorFilterHasValue$.next($event)"></app-input-debounce>
              </div>

            </div>
            <div class="row">
              <div class="col text-left text-info" style="padding: 0px 4px 4px 4px; margin: 0px;">
                <small>Pro tip: "%" acts as a wildcard sign. Examples: "you<u><b>%</b></u>leads" will match "You<u> have
                  many </u>leads". "product_news_<b>%</b>1" will match "product_news_<b>16</b>12".</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col text-left">
            <div class="btn-group-toggle">
              <label>ModificationLayer</label>
              <div class="btn-group btn-group-sm btn-sm btn-group-toggle" style="flex-wrap: wrap;" data-toggle="buttons">
                <label [ngClass]="{'btn': true, 'btn-info': true, 'active': currentModificationLayerTagFilter === null}"
                       (click)="this.selectModificationLayerSearchFilter(null)">
                  <input type="radio" name="layeroptions" id="NO"> Don't care
                </label>
                <ng-container *ngIf="availableModificationLayers$ | async as layers">
                  <ng-container *ngFor="let layer of layers">
                    <label
                      [ngClass]="{'btn': true, 'btn-info': true, 'active': currentModificationLayerTagFilter === layer.tag}"
                      (click)="this.selectModificationLayerSearchFilter(layer.tag)">
                      <input type="radio" name="layeroptions" id="LAYER_{{layer.tag}}"
                             value="{{layer.tag}}"> {{layer.displayName}} [{{layer.tag}}]
                    </label>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>


        <div class="row" style="margin-top: 16px"><h6><u>Sort</u></h6></div>
        <div class="row bg-light">
          <div class="col-7 text-left">
            <div class="btn-group-toggle">
              <label>
                Sort on</label>
              <div class="btn-group btn-group-sm btn-sm btn-group-toggle" (click)="onSortingChange($event)"
                   data-toggle="buttons">
                <label [ngClass]="{'btn': true, 'btn-info': true, 'active': sortProperty === 'Key Created Date'}">
                  <input type="radio" name="options" id="CreatedDate" value="Key Created Date" checked> Created date
                </label>
                <label [ngClass]="{'btn': true, 'btn-info': true, 'active': sortProperty === 'Key Created By'}">
                  <input type="radio" name="options" id="KeyCreatedBy" value="Key Created By"> Created By
                </label>
                <label [ngClass]="{'btn': true, 'btn-info': true, 'active': sortProperty === 'Key Name'}">
                  <input type="radio" name="options" id="KeyName" value="Key Name"> Key Name
                </label>
              </div>

            </div>
          </div>
          <div class="col-5 text-left">
            <div class="btn-group-toggle">
              <label>
                In
                <div class="btn-group btn-group-sm btn-sm btn-group-toggle" (click)="onOrderChange($event)"
                     data-toggle="buttons">
                  <label [ngClass]="{'btn': true, 'btn-info': true, 'active': sortOrder === 'Descending'}">
                    <input type="radio" name="options" id="Descending" value="Descending" checked> Descending
                  </label>
                  <label [ngClass]="{'btn': true, 'btn-info': true, 'active': sortOrder === 'Ascending'}">
                    <input type="radio" name="options" id="Ascending" value="Ascending"> Ascending
                  </label>
                </div>
                Order
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs " *ngIf="selectedLanguageCode != null" [@fadeInOut] style="padding: 2px; margin: 8px;">

      </div>


      <div class="col">
        <div class="row" *ngIf="selectedLanguageCode != null" [@fadeInOut]><h6><u>Language and Workflow</u></h6></div>
        <div class="row" style="margin-bottom: 5px;">
          <div class="col text-left"
               [ngClass]="{'highlight': selectedLanguageCode == null, 'noHighlight': selectedLanguageCode != null}">
            <app-language-list text="Please select language to translate"
                               (languageSelectedEvent)="selectLanguage($event)"
                               [$selectedLanguageCode]="$selectedLanguageCode"></app-language-list>
          </div>
        </div>
        <div class="row bg-light" *ngIf="selectedLanguageCode !== null" style="margin-bottom: 5px;" [@fadeInOut]>

          <div class="col" style="margin-right: 0px; padding-right: 0px">
            <app-language-list text="View additional language:"
                               [$selectedLanguageCode]="$selectedAdditionalLanguageCode"
                               (languageSelectedEvent)="additionalLanguageSelected($event)"></app-language-list>
          </div>
          <div class="col col-md-auto text-left" style="margin-left:0px; padding-left: 0px;">
            <button class="btn btn-outline-danger btn-sm"
                    (click)="clearAdditionalLanguage()"
                    *ngIf="selectedAdditionalLanguageCode != null && selectedAdditionalLanguageCode !== ''"
                    style="padding:2px; margin:2px;">
              Clear Additional Language
            </button>
          </div>
        </div>

        <div class="row" *ngIf="selectedLanguageCode !== null" [@fadeInOut]>
          <div [ngClass]="{'col':true, 'text-left': true, 'flash': doFlashJumpSettings}">
            <div class="btn-group-toggle">
              <label class="large">
                <input type="checkbox" #jumpWhenSavingCheckBox [defaultChecked]="true"
                       (click)="onCheckBoxValueChangedForJumpWhenSaving($event)"><a style="font-size: medium; margin-left: 4px;">After
                saving
                a translation,
                automatically
                focus on the next untranslated one.</a>
              </label>
            </div>
            <div class="btn-group-toggle" style="margin-left: 50px">
              <label class="medium">
                &#x21B3;
                <input type="checkbox" #jumpWhenSavingOnlyWhenUntranslatedCheckBox [defaultChecked]="true"
                       (click)="onCheckBoxValueChangedForJumpWhenSavingOnlyWhenUntranslated($event)" style="margin-left: 4px"><a style="font-size: small; margin-left: 4px">Only when the saved translation was previously untranslated.</a>
              </label>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="selectedLanguageCode != null" style="margin-top: 16px"><h6><u>And finally ...</u></h6>
        </div>
        <div class="row" *ngIf="selectedLanguageCode !== null" [@fadeInOut]>
          <div class="col text-left">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="setExpandFilterBar(false)">This big,
              ugly
              window, clearly designed by someone who should not be allowed to design things,<br> is hogging all of my
              screens available real estate. Make it go away! (Yes, this is a button)
            </button>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>


<!--<ng-container *ngIf="(translationContext$ | async) as context">-->
<div class="container-fluid justify-content-center">
  <div class="row">
    <div class="col">
      <ng-container *ngIf="translationContext$ | async">
        <app-language [translationContext$]="translationContext$"
                      [jumpToNextUntranslatedAfterSaving$]="jumpToNextUntranslatedAfterSaving$"
                      [jumpToNextUntranslatedAfterSavingOnlyWhenUntranslated$]="jumpToNextUntranslatedAfterSavingOnlyIfUntranslated$"
                      (onJumpingToNextTranslationAfterSave)="onJumpingToNextTranslationAfterSave()"
        ></app-language>
      </ng-container>
    </div>
  </div>
</div>
<!--</ng-container>-->
