import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {PermissionService} from '../permissions/permission.service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {delay, map, retryWhen, share, shareReplay, switchMap, take} from 'rxjs/operators';
import {User} from './user';
import {UserPermissions} from '../permissions/user-permissions';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userObservable: Subject<User> = new ReplaySubject<User>(1);

  constructor(private http: HttpClient, private permissionService: PermissionService) {
    this.getCurrentUser().pipe(
      switchMap(userMap => this.createUserEntityFrom(userMap, this.permissionService.getCurrentUsersPermissions())),
    ).toPromise()
      .then(
        value => {
          this.userObservable.next(value);
        }
      )
      .catch(
        reason => alert('Unable to fetch the User-information after many retries. Please do a hard reload of the DSW-admin webapp. Error was: ' + reason.message)
      );
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get('/user/').pipe(
      switchMap((users: User[]) => this.listOfUsersToUserEntities(users)),
      share()
    );
  }

  async createUserIncludingUserPermissions(oneUser): Promise<User> {
    const usersPermissions = this.permissionService.getPermissionsFor(oneUser.id);
    return await this.createUserEntityFrom(oneUser, usersPermissions);
  }

  private async listOfUsersToUserEntities(users: User[]): Promise<User[]> {
    const userList = new Array<User>();
    for (const user of users) {
      const userWithPermission = await this.createUserIncludingUserPermissions(user);
      userList.push(userWithPermission);
    }
    return userList;
  }

  getUser(): Observable<User> {
    return this.userObservable;
  }

  private getCurrentUser(): Observable<User> {
    return this.http.get('/user/self').pipe(
      map((user: User) => user),
      retryWhen(errors => errors.pipe(delay(5000), take(1000))),
      shareReplay(1)
    );
  }


  private async createUserEntityFrom(user: User, $usersPermissions: Observable<UserPermissions>): Promise<User> {
    const permissions: UserPermissions = await $usersPermissions.toPromise();

    const completeUser: User = new User(
      user.username,
      user.realName,
      user.expirationDate,
      permissions,
      user.id
    );
    return completeUser;
  }

  deleteUser(user: User): Observable<any> {
    return this.http.delete('/user/' + user.id);
  }

  createNewTranslator(value: any): Observable<any> {
    return this.http.post('/user/createTranslator/' + value.language, value);
  }

  createNewAdmin(value: any) {
    return this.http.post('/user/createAdmin', value);
  }
}
