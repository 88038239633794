<editor
  [init]="{
  plugins: 'lists link image table code help wordcount advlist fullscreen preview visualblocks media',
  toolbar: 'blocks | fontsize bold italic underline forecolor backcolor | bullist numlist |  alignleft aligncenter alignright alignjustify | outdent indent | undo redo | fullscreen | preview |  media image | help',
  promotion: false,
  menubar: true,
  outputFormat: 'html',
  height: 600,
  }"
  [(ngModel)]="currentText"
  [modelEvents]="'change input undo redo'"
  (ngModelChange)="onChange($event)"
 ></editor>
