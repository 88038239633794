import {Injectable} from '@angular/core';
import {ImprovedRestService} from '../../improved-rest.service';
import {TriggerableUrl} from './triggerable-url';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TriggerableUrlService extends ImprovedRestService<TriggerableUrl> {

  constructor() {
    super(TriggerableUrl);
  }

  public triggerUrl(triggerableUrl: TriggerableUrl): Observable<void> {
    return this.customQueryPost('/' + triggerableUrl.id + '/actions/triggerUrl').pipe(map(response => response as any as void));
  }

}
