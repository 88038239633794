import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Translation} from '../../../../services/hal/translation/translation';
import {Language} from '../../../../services/hal/language/language';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {TranslationKey} from '../../../../services/hal/translationkey/translation-key';
import {TranslationService} from '../../../../services/hal/translation/translation.service';
import {ModificationLayer} from '../../../../services/hal/modificationlayer/modification-layer';
import {map, shareReplay} from 'rxjs/operators';
import {ModificationLayerService} from '../../../../services/hal/modificationlayer/modification-layer.service';
import {UserService} from '../../../../services/domain/user/user.service';
import {User} from '../../../../services/domain/user/user';
import {ImprovedResource} from '../../../../services/improved-resource';
import {TranslationEventDto} from '../../translation-key/translation-key/translation-event-dto';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit, OnDestroy {
  protected readonly ImprovedResource = ImprovedResource;

  public newLineRegexp: RegExp = new RegExp('\n', 'g');

  @ViewChild('textAreaForTranslationEditing', {static: true}) textAreaForTranslationEditing: ElementRef<HTMLTextAreaElement>;

  @Input() language$: Observable<Language>;
  @Input() translationKey$: Observable<TranslationKey>;
  @Input() showLanguage = false;
  @Input() translation: Translation;

  @Output() translationWasUpdatedByUserEvent: EventEmitter<TranslationEventDto> = new EventEmitter();
  @Output() modificationLayerTranslationDeletedByUser: EventEmitter<Translation> = new EventEmitter();
  currentKey: TranslationKey;
  userHasModifiedContentTemporarily = false;

  modificationLayer$: Observable<ModificationLayer>;

  userMayDeleteModificationLayerTranslation$: Observable<boolean>;

  public isEditing: boolean;
  public useHtmlEditor: boolean;
  public contentIsAltered: boolean;
  wasInitiallyUntranslated: boolean;

  showExplosion = false;

  constructor(private translationService: TranslationService, private modificationLayerService: ModificationLayerService, private userService: UserService) {
  }

  private keyUpdatedSubscription: Subscription;

  ngOnInit() {
    this.subscribeToUpdatesOfKeyAndTranslationToPopulateLocalFields();
    this.modificationLayer$ = this.getModificationLayer().pipe(shareReplay(1));
    if (!ImprovedResource.isExistingInBackend(this.translation)) {
      this.setEditMode(true);
    }

    this.userMayDeleteModificationLayerTranslation$ = combineLatest(this.language$, this.userService.getUser()).pipe(
      map(languageAndUser => this.mayUserDeleteTranslation(languageAndUser[0], languageAndUser[1], this.translation)),
      shareReplay(1)
    );
    this.wasInitiallyUntranslated = !this.translation.translated;
  }


  private subscribeToUpdatesOfKeyAndTranslationToPopulateLocalFields() {
    /* this.translationUpdatedSubscription = this.translation$.subscribe(
       newTranslation => this.handleTranslationUpdated(newTranslation)
     );
     */
    this.keyUpdatedSubscription = this.translationKey$.subscribe(
      key => this.currentKey = key
    );
  }

  toggleEditMode($event: Event) {
    $event.stopPropagation();
    this.setEditMode(!this.isEditing);
  }

  setEditMode(isEditing: boolean) {
    this.isEditing = isEditing;
    this.onAfterEditModeChange();
  }

  private onAfterEditModeChange() {
    this.toggleHighlightOfComponentDependingOnIfContentIsModifiedButUnsaved();
    if (this.isEditing) {
      this.useHtmlEditor = this.shouldContentBeTreatedAsHTML();
      setTimeout(() => {
        if (!this.useHtmlEditor) {
          this.putFocusOnTextTranslation();
        } else {
          this.putFocusOnHtmlEditor();
        }
      }, 0);
    }
  }

  private putFocusOnHtmlEditor() {
    this.textAreaForTranslationEditing.nativeElement.scrollIntoView({block: 'center'}); // This is located right underneath the HTML-editor.
  }

  private putFocusOnTextTranslation() {
    this.textAreaForTranslationEditing.nativeElement.focus({preventScroll: false});
    this.textAreaForTranslationEditing.nativeElement.scrollIntoView({block: 'center'});
    if (!this.translation.translated) {
      this.textAreaForTranslationEditing.nativeElement.setSelectionRange(0, this.translation.translation.length);
    }
  }

  private shouldContentBeTreatedAsHTML() {
    return this.isContainsHtml(this.translation.translation) || this.isKeyOfPredeterminedHtmlType(this.currentKey.name);
  }

  private isContainsHtml(translation: string) {
    return translation.indexOf('<') !== -1 && translation.indexOf('>') !== -1;
  }

  userRequestsCancelOfEdit($event: UIEvent) {
    $event.stopPropagation();
    this.cancelEditingMode();
  }

  private cancelEditingMode() {
    this.setEditMode(false);
  }

  toggleHighlightOfComponentDependingOnIfContentIsModifiedButUnsaved() {
    this.contentIsAltered = this.translation.translation !== this.textAreaForTranslationEditing.nativeElement.value;
  }

  saveChanges($event: UIEvent) {
    $event.stopPropagation();
    try {
      this.setEditMode(false);
      this.updateStateOfCurrentTranslationToReflectUserInput();
      this.translationService.createOrUpdateTranslation(this.translation)
        .catch(
          error => this.onFailedToSaveTranslation(error)
        )
        .then(
          () => this.onTranslationUpdatedSuccessfully()
        );
    } catch (e) {
      this.setEditMode(true);
    }
  }

  private updateStateOfCurrentTranslationToReflectUserInput() {
    this.translation.translation = this.textAreaForTranslationEditing.nativeElement.value;
    this.translation.translated = true;
  }

  private onFailedToSaveTranslation(error) {
    alert('Failed to update the translation: ' + error.message);
    this.setEditMode(true);
  }

  private onTranslationUpdatedSuccessfully() {
    this.setEditMode(false);
    this.translationWasUpdatedByUserEvent.emit(
      {
        newTranslation: this.translation,
        wasPreviouslyUntranslated: this.wasInitiallyUntranslated
      });
    this.wasInitiallyUntranslated = this.translation.translated;
  }

  userRequestsResetOfEdit($event: Event) {
    $event.stopPropagation();
    this.useHtmlEditor = false;
    this.textAreaForTranslationEditing.nativeElement.value = this.translation.translation;
    this.userHasModifiedContentTemporarily = false;
    this.toggleHighlightOfComponentDependingOnIfContentIsModifiedButUnsaved();
    this.cancelEditingMode();
  }

  setIsEditingUnlessAlreadyEditing($event: UIEvent) {
    $event.stopPropagation();
    if (!this.isEditing) {
      this.setEditMode(true);
    }
  }

  onEditorKeyPress(event: KeyboardEvent) {
    console.log(event);
    if (event.code === 'Enter' && event.ctrlKey === true) {
      this.saveChanges(event);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Esc') {
      this.userRequestsCancelOfEdit(event);
    }
  }

  public isTranslated(): boolean {
    return this.translation.translated;
  }

  public isModificationLayer(): boolean {
    return this.translation.forModificationLayer;
  }

  private getModificationLayer(): Observable<ModificationLayer> {
    if (ImprovedResource.isExistingInBackend(this.translation)) {
      return Translation.getModificationLayer(this.translation);
    }
    return of(this.translation.modificationLayer);
  }

  public isRepresentingBaseTranslation(): boolean {
    return !this.translation.forModificationLayer;
  }

  forceSetAsTranslated(event: MouseEvent) {
    event.stopPropagation();
    if (this.userHasModifiedContentTemporarily) {
      return;
    }
    if (!confirm('The text of this translation will be marked as Translated, even though its translation does not differ from that of the English one. This might be desirable when for example the english word is the same as the translated word. \nPlease confirm that this is desirable.')) {
      return;
    }
    this.setEditMode(false);
    this.translation.translated = true;
    try {
      this.translationService.update(this.translation).toPromise()
        .then(() => this.onTranslationUpdatedSuccessfully())
        .catch(error => this.onFailedToSaveTranslation(error));
    } catch (e) {
      this.setEditMode(true);
    }
  }

  valueChange() {
    this.userHasModifiedContentTemporarily = this.textAreaForTranslationEditing.nativeElement.value !== this.translation.translation;
  }

  ngOnDestroy(): void {
    this.keyUpdatedSubscription.unsubscribe();
  }

  private formatDateBeautifully(date: string): string {
    const asDate: Date = new Date(Date.parse(date));
    return asDate.toLocaleString();
  }

  private createDefenceSpeechForTranslation(translation: Translation): string {

    let speech = 'At ' + this.formatDateBeautifully(translation.createdDate) + ', ' + translation.createdBy.toUpperCase() + ' conceived this lovely translation.\n\n' +
      'Many hours of writing and careful planning was surely put into this amazing translation. A masterpiece of its own.\n\n';
    if (translation.updatedDate) {
      speech = speech + 'But! That was not good enough for Quedro.\n\n' +
        'After careful reviewing and consideration, ' + translation.updatedBy.toUpperCase() + ' got an idea. A most genius idea.\n\n ' +
        'At ' + this.formatDateBeautifully(translation.updatedDate) + ' ' + translation.updatedBy + ' updated this translation to take its current form. And thus it became what it is today.\n\n' +
        'During its lifetime, this translation was updated a total of ' + translation.version + ' time(s), each time a more beautiful improvement than the other.\n\n';
    } else {
      speech = speech + 'A poem in on its own, the translation never was in need of an update. It has remained truly magnificent and untouched right up until this very moment.\n\n';
    }
    speech = speech + 'And that is the captivating story behind the great success of this translation, and in no small part also the success of the great company that is Quedro.\n\n' +
      '\t\t- Yours truly, DeSwinglisher';

    return speech;
  }

  deleteModificationLayerTranslation(translation: Translation) {
    alert(this.createDefenceSpeechForTranslation(translation));
    if (confirm('Destroy?')) {
      this.modificationLayerService.deleteModificationLayerTranslation(translation).toPromise()
        .then(() => {
          this.showExplosion = true;
          setTimeout(() => {
            this.showExplosion = false;
            this.modificationLayerTranslationDeletedByUser.emit(translation);
          }, 2000);
        })
        .catch(error => alert('That most definitely did not work: ' + error.message));
    }
  }

  private mayUserDeleteTranslation(language: Language, user: User, translation: Translation) {
    if (!ImprovedResource.isExistingInBackend(translation)) {
      return false;
    }
    if (!translation.forModificationLayer) {
      return false;
    }

    return user.authorities.mayAlterLanguage(language.languageCode) || user.authorities.hasRole('ADMIN');
  }


  onHtmlEditorContentChanged(newContent: string) {
    this.updateTranslationAreaTextBasedOnInputFromOtherSource(newContent);
  }

  private updateTranslationAreaTextBasedOnInputFromOtherSource(newContent: string) {
    this.textAreaForTranslationEditing.nativeElement.value = newContent;
    this.valueChange();
  }

  userTogglesUseOfHtmlEditor() {
    this.useHtmlEditor = !this.useHtmlEditor;
  }

  private isKeyOfPredeterminedHtmlType(translationKeyName: string) {
    return translationKeyName.toLowerCase().startsWith('productnews_');
  }


}
