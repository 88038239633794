<ng-container *ngIf="(translationKey$ | async) as key">
  <button type="button" class="btn btn-sm btn-outline-secondary" data-toggle="modal"
          [attr.data-target]="'#desistModal_' + key.name" [hidden]="!showButton" style="padding: 2px 10px"
          (click)="loadImages = true"
          #showButtonElement>
    <i class="fa fa-trash" style="font-size: small"></i> Desist
  </button>
  <!-- Modal -->

  <div class="modal fade" [id]="'desistModal_' + key.name" [attr.aria-labelledby]="'#modalLabel_' + key.name"  tabindex="-1" aria-hidden="true" data-backdrop="false" #modal>
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="background-color: ivory">
        <div class="modal-header">
          <h2 class="modal-title" [id]="'modalLabel_' + key.name">Desist "{{key.name}}"?</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-success" data-dismiss="modal"><img *ngIf="loadImages" src="assets/no.gif" alt="Cancel"
                                                                                    style="max-height:200px"/></button>
          <div style="width:100%"></div>
          <button type="button" class="btn btn-outline-danger"><img *ngIf="loadImages" src="assets/yes.gif" alt="Yes" style="max-height:200px"
                                                             (click)="desistTranslationKey($event)"/></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
