import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LanguageService} from '../../../../services/hal/language/language.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Language} from '../../../../services/hal/language/language';
import {shareReplay} from 'rxjs/operators';
import {VisualProgressComponent} from '../../../controls/visual-progress/visual-progress.component';

@Component({
  selector: 'app-language-editor',
  templateUrl: './language-editor.component.html',
  styleUrls: ['./language-editor.component.css']
})
export class LanguageEditorComponent implements OnInit, OnDestroy {

  @Input() editedLanguage$?: Observable<Language> = new BehaviorSubject(new Language()).pipe(shareReplay(1));

  @ViewChild('visualProcessor', { static: true }) visualProcessorComponent: VisualProgressComponent<Language>;

  currentlyEditedLanguage: Language;
  
  editedLanguageSubscription: Subscription;

  constructor(private languageService: LanguageService) {
  }

  isEdit(): boolean {
    return this.currentlyEditedLanguage.id !== undefined;
  }

  ngOnInit() {
    this.editedLanguageSubscription = this.editedLanguage$.subscribe(newLanguage => this.currentlyEditedLanguage = newLanguage);
  }

  ngOnDestroy(): void {
    this.editedLanguageSubscription.unsubscribe();
  }

  saveLanguage() {
    if (this.isEdit()) {
      this.assertUserIsSureAndUpdate();
    } else {
      this.assertUserIsSureAndCreate();
    }
  }

  private assertUserIsSureAndUpdate() {
    if (confirm('Update to ' + this.currentlyEditedLanguage.displayName + ' [' + this.currentlyEditedLanguage.languageCode + ']? Seriously?')) {
      alert('Weird. Suit yourself.');
      this.visualProcessorComponent.processTask(this.languageService.updateLanguage(this.currentlyEditedLanguage));
    } else {
      alert('Cluuuuck cluck cluck!!!');
    }
  }

  private assertUserIsSureAndCreate() {
    if (confirm('Create Language ' + this.currentlyEditedLanguage.displayName + ' [' + this.currentlyEditedLanguage.languageCode + ']?')) {
      alert('Alright. Will create a translation for each existing key for the new language. This might take some time.');
      this.visualProcessorComponent.processTask(this.languageService.createLanguage(this.currentlyEditedLanguage));
    } else {
      alert('Cluuuuck cluck cluck!!!');
    }
  }
}
