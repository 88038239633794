<button type="button" class="btn btn-outline-primary " data-toggle="modal" data-target="#newTranslationKeyModal"
        (click)="onPopupRequested()">
  + TranslationKey
</button>

<!-- Modal -->
<div class="modal fade" id="newTranslationKeyModal" tabindex="-1" role="dialog"
     aria-labelledby="newTranslationKeyModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newTranslationKeyModalLabel">Create Translation Key</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Key name<br>
        <input #keyName type="text" style="width:100%" [(ngModel)]="keyNameInput"  #keyNameModel="ngModel" pattern="[a-z0-9_]*" />

        <br><br>

        Translation (EN)<br>
        <textarea #translation class="form-control" style="font-size: large; width: 100%"
                  [rows]="5" (keydown.control.enter)="doCreateTranslationKey( true)"
                  (keydown.esc)="closeModal()" [(ngModel)]="translationInput"></textarea>

        <div *ngIf="keyNameModel.invalid && (keyNameModel.dirty)" class="text-danger">

          <img src="assets/blame-wrong.png" alt="INVALID" style="max-width: 100%" class="slap" />
          <p style="font-size:small">Rules exists for a reason, and you are not following them.<br>
            A TranslationKey:s name may consist of: <u>lowercase</u> letters, digits, and underscores.<br>Example: <code>you_are_a_badass_for_not_following_the_established_rul3s</code></p>
        </div>

        <ng-container *ngIf="wait">
          <a class="text-info">Creating, please wait ...</a>
        </ng-container>
        <ng-container *ngIf="error != null">
          <img src="assets/wrong.jpg" alt="WRONG" style="max-height: 56px;"/>
          <a class="text-danger">The shit has successfully hit the fan: <br>{{error.message}}</a>
        </ng-container>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="success">
          <a style="color:darkgreen; margin-right: 20px">Det sket sig inte.</a>
        </ng-container>
        <button #cancelButton type="button" class="btn btn-secondary" data-dismiss="modal">Close<br><small><u>(ESC)</u></small>
        </button>
        <button type="button" class="btn btn-info" (click)="doCreateTranslationKey( false)">Create</button>
        <button type="button" class="btn btn-primary" (click)="doCreateTranslationKey( true)">Create and View<br><small><u>(CTRL
          + ENTER)</u></small></button>
      </div>
    </div>
  </div>
</div>
