import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserService} from '../../../services/domain/user/user.service';
import {User} from '../../../services/domain/user/user';
import {Release} from '../../../services/hal/release/release';
import {ReleaseService} from '../../../services/hal/release/release.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  $user: Observable<User>;
  $latestRelease: Observable<Release>;

  date = Date;

  downloading: boolean;


  constructor(private userService: UserService, private releaseService: ReleaseService) {
  }

  ngOnInit() {
    this.$user = this.userService.getUser();
    this.$latestRelease = this.releaseService.getLatestRelease();
  }

  onDownloadTranslations() {
    this.downloading = true;
    setTimeout(() => this.downloading = false, 8000);
  }
}
