import {of, OperatorFunction, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export function printHere<T>(position?: string): OperatorFunction<T, T> {
  return map((input) => {
    console.log('STATE' + (position ? '@' + position : '') + ': ' + JSON.stringify(input));
    return input;
  });
}

function isServersideError(errorResponse: HttpErrorResponse) {
  return errorResponse.error instanceof Error;
}

export function catch404ThenReturnNull<T>(position?: string): OperatorFunction<T, T> {
  return catchError((errorResponse: HttpErrorResponse) => {
    if (!isServersideError(errorResponse) && errorResponse.status === 404) {
      return of(null);
    }
    return throwError(errorResponse);
  });
}

export function printThis<T>(object: any): OperatorFunction<T, T> {
  return map((input) => {
    console.log('OBJECT STATE: ' + JSON.stringify(object));
    return input;
  });
}
