<ng-container *ngIf="(language$ | async) as theLanguage">
  <ng-container *ngIf="isClickable; else notClickable;">
    <button [ngClass]="{'btn btn-sm': true, 'btn-outline-primary': isHighlighted}" (click)="onClick(theLanguage)">
      {{theLanguage.displayName | titlecase}} [{{theLanguage.languageCode}}]
    </button>
  </ng-container>
  <ng-template #notClickable>
    <a>{{theLanguage.displayName | titlecase}} [{{theLanguage.languageCode}}]</a>
  </ng-template>
</ng-container>
